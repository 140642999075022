export default {
  methods: {
    getEstablecimientos() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var usuario = JSON.parse(localStorage.getItem('usuario'));
      var url = window.global.url + "establecimientos";
      app.preloader.show();
      app.request.json(url, function (response) {
        if (response.status == 'success') {
          // console.log(response);
          var establecimientos = response.establecimientos;
          establecimientos.forEach(function (establecimiento, i) {
            $('[name="establecimientos"]').append($('<option>').text(establecimiento.nombre).attr('value', establecimiento.id_establecimiento));
            $('[name="establecimientos"] option[value="' + establecimiento.id_establecimiento + '"]').prop('selected', false);
          }); // $('.smart-select .item-after').text("Seleccionar");

          app.preloader.hide();
          self.getUsuario();
        }
      });
    },

    getUsuario() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idUsuario = JSON.parse(localStorage.getItem('usuario')).id_usuario;
      var url = window.global.url + "usuario/establecimientos/" + idUsuario;
      app.preloader.show();
      app.request.getJSON(url, function (data) {
        if (data.status == 'success') {
          var usuario = data.usuario_establecimientos;
          var nombre = $('input[type=text][name="nombre"]').val(usuario.nombre);
          var apellido = $('input[type=text][name="apellido"]').val(usuario.apellido);
          var email = $('input[type=email][name="email"]').val(usuario.email);
          var rol = $('input[type=text][name="rol"]').val(usuario.rol); // Arr auxiliar para seleccionar en el smart-select

          let establecimientosSelectValue = [];
          var smartSelect = app.smartSelect.get('.smart-select'); // Selecciona el checkbox con idEstablecimiento

          usuario.establecimientos.forEach(function (establecimiento, i) {
            $('.page[data-name="superadmin-usuario-mod"] [name="establecimientos"] option[value="' + establecimiento.id_establecimiento + '"]').prop('selected', true);
            establecimientosSelectValue.push(JSON.stringify(establecimiento.id_establecimiento));
          });
          smartSelect.setValue(establecimientosSelectValue);

          if (usuario.estado) {
            $('.page[data-name="superadmin-usuario-mod"] input[type=checkbox][name=estado-usuario-checkbox]').prop("checked", true);
          } else {
            $('.page[data-name="superadmin-usuario-mod"] input[type=checkbox][name=estado-usuario-checkbox]').prop("checked", false);
          }

          app.preloader.hide();
        } else {
          app.preloader.hide();
          app.dialog.alert("Error al obtener usuario.");
        }
      }, function (xhr, status) {
        app.preloader.hide();
        console.log(xhr);
        console.log(status);
        app.dialog.alert("Error al obtener usuario");
      });
    },

    modificarUsuario() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idUsuario = JSON.parse(localStorage.getItem('usuario')).id_usuario;
      var url = window.global.url + "usuarios/" + idUsuario;
      var smartSelect = app.smartSelect.get('.smart-select');
      var nombre = $('input[type=text][name="nombre"]').val().trim();
      var apellido = $('input[type=text][name="apellido"]').val();
      var email = $('input[type=email][name="email"]').val();
      var password = $('input[type=password][name="password"]').val();
      var rol = $('input[type=text][name="rol"]').val();
      var establecimientos = smartSelect.getValue();
      var estado = 1;
      var usuario = {
        nombre: nombre,
        apellido: apellido,
        email: email,
        password: password,
        establecimientos: establecimientos,
        rol: rol,
        estado: estado
      };
      app.preloader.show();
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'PUT',
        data: usuario,
        statusCode: {
          404: function (xhr) {
            console.log("URL not found");
          },
          400: function (xhr) {
            console.log("Bad request. Some of the inputs provided to the request aren't valid.");
          },
          401: function (xhr) {
            console.log("Not authenticated. The user session isn't valid.");
          },
          403: function (xhr) {
            console.log("The user isn't authorized to perform the specified request.");
            app.views.main.router.navigate({
              path: '/'
            });
          },
          500: function (xhr) {
            console.log("Internal server error. Additional details will be contained on the server logs.");
          },
          201: function (xhr) {
            console.log("The requested resource has been created.");
          }
        },
        success: function (data, status, xhr) {
          // console.log(data);
          if (data.status == 'success') {
            console.log(data);
            app.dialog.alert("Usuario modificado con éxito.");
            self.validarCampos();
          } else {
            app.dialog.alert("Error al modificar usuario.");
          }

          app.preloader.hide();
        },
        error: function (xhr, status) {
          app.preloader.hide();
          console.log(xhr); // console.log(JSON.stringify(xhr));

          console.log(status);
          app.dialog.alert("Error al modificar usuario.");
        }
      });
    },

    validarCampos() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var nombre = $('input[type=text][name="nombre"]').val().trim();
      var apellido = $('input[type=text][name="apellido"]').val().trim();
      var email = $('input[type=email][name="email"]').val().toLowerCase().trim();
      var rol = $('input[type=text][name="rol"]').val().toLowerCase().trim();
      var smartSelect = app.smartSelect.get('.smart-select');
      var establecimientos = smartSelect.getValue();

      if (nombre.length < 3 || !email || !rol || establecimientos.length < 1) {
        $('.modificar-usuario-button').addClass('disabled');
        return false;
      }

      $('.modificar-usuario-button').removeClass('disabled');
      return true;
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getEstablecimientos();
      $('input[type=text][name=nombre], input[type=text][name="apellido"], input[type=email][name="email"], input[type=password][name="password"], input[type=text][name="rol"]').on('input', function () {
        self.validarCampos();
      });
      $('.page[data-name="superadmin-mis-datos"] select[name="establecimientos"]').on('change', function () {
        self.validarCampos();
      });
      $('.modificar-usuario-button').on('click', function () {
        self.modificarUsuario();
      });
    }

  },
  id: '88763dd15d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=superadmin-mis-datos><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Mis datos</div><div class=right></div></div></div><div class=page-content><div class=block-title>Usuario</div><div class="list no-hairlines-md"><ul><li><a class="item-link smart-select smart-select-init"><select name=establecimientos multiple=multiple required validate></select><div class=item-content><div class=item-inner><div class=item-title>Establecimiento</div></div></div></a></li><li class="item-content item-input disabled"><div class=item-inner><div class="item-title item-label">Rol</div><div class=item-input-wrap><input type=text name=rol value=administrador pattern=.{6,}></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Nombre</div><div class=item-input-wrap><input type=text name=nombre placeholder=Nombre pattern=.{3,} required validate data-error-message="El nombre debe tener al menos 3 caracteres."></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Apellido</div><div class=item-input-wrap><input type=text name=apellido placeholder=Apellido pattern=.{3,} required validate data-error-message="El apellido debe tener al menos 3 caracteres."></div></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">Email</div><div class=item-input-wrap><input type=email name=email placeholder=Email required validate></div></div></li></ul></div><div class=block><p class=row><a href=change-password class="col button button-round">Cambiar contraseña</a></p><p class=row><a href=# class="col button button-round button-fill modificar-usuario-button disabled">Guardar Cambios</a></p></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};