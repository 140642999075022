export default {
  methods: {
    truncate(str, n, useWordBoundary) {
      if (str.length <= n) {
        return str;
      }

      const subString = str.substr(0, n - 1); // the original check

      return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) : subString) + "&hellip;";
    },

    formatDate(fecha) {
      var date = new Date(fecha);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();

      if (day < 10) {
        day = '0' + day;
      }

      if (month < 10) {
        month = '0' + month;
      }

      var formattedDate = day + '/' + month + '/' + year;
      return formattedDate;
    },

    getNovedades() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var usuario = JSON.parse(localStorage.getItem('usuario'));
      var url = window.global.url + "novedades";
      app.preloader.show();
      app.request.json(url, function (response) {
        if (response.code == 200) {
          for (let i = 0; i < response.novedades.length; i++) {
            const novedad = response.novedades[i];
            let novedadTemplate = `<div class="card" data-id-novedad="` + novedad.id_novedad + `">
                                                    <div class="card-header text-color-white novedad-titulo"></div>
                                                    <div class="card-content card-content-padding">
                                                    <p class="date">Publicado el ` + self.formatDate(novedad.created_at) + `</p>
                                                    <p class="novedad-contenido-corto"></p>
                                                    </div>
                                                    <div class="card-footer"><a href="` + novedad.id_novedad + `" class="link">Ver más</a></div>
                                                </div>`;
            $('.page[data-name="admin-novedades"] .vl-novedades').append(novedadTemplate);
            novedad.contenido = self.truncate(novedad.contenido, 100, 1);
            $('.page[data-name="admin-novedades"] .vl-novedades [data-id-novedad="' + novedad.id_novedad + '"] .novedad-titulo').html('<b>' + novedad.titulo + '</b>');
            $('.page[data-name="admin-novedades"] .vl-novedades [data-id-novedad="' + novedad.id_novedad + '"] .novedad-contenido-corto').html(novedad.contenido.replace(/<[^>]*>/g, ''));
          }
        }

        app.preloader.hide();
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getNovedades();
    }

  },
  id: '1f8a10cb24',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=admin-novedades><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Novedades</div><div class=right></div><div class=subnavbar><form data-search-container=.vl-novedades data-search-item=.card data-search-in=.novedad-titulo class="searchbar searchbar-init"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Buscar novedades"> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancelar</span></div></form></div></div></div><div class=searchbar-backdrop></div><div class=page-content><div class="row justify-content-center"><div class="col-100 medium-100 large-50"><div class="list simple-list no-hairlines searchbar-not-found"><ul><li>No hay resultados.</li></ul></div><div class="list vl-novedades searchbar-found"></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
    img {
        object-fit: cover;
    }
    
    .card .card-content-padding .date {
        color: #8e8e93;
    }
    
    .card .card-header {
        /* background: #9796f0; */
        /* background: -webkit-linear-gradient(to left, #fbc7d4, #9796f0); */
        /* background: linear-gradient(to left, #fbc7d4, #9796f0); */
        background: #FF5F6D;
        background: -webkit-linear-gradient(to left, #FFC371, #FF5F6D);
        background: linear-gradient(to left, #FFC371, #FF5F6D);
    }
`,
  styleScoped: false
};