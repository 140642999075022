import { get, listItemEstablecimiento, totalEstablecimiento, quantity, remove, destroy } from '../js/cart-sessionstorage/cart-sessionstorage.js';
export default {
  methods: {
    listarItemsCarrito() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      let tipoEnvio = sessionStorage.getItem('_tipoenvio');
      let urlImagen = window.global.url + "imagen/item/";
      let items = listItemEstablecimiento(idEstablecimiento);
      $('.virtual-list-carrito').empty();

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        let itemTemplate = '<div data-id-item="' + item.id + '" class="item-content item-custom-class-' + item.id + '">' + // '<div class="item-media"><img class="img-item" src="' + urlImagen + item.id + '/' + item.imagen + '"></div>' +
        '<div class="item-inner">' + '<div class="item-title-row">' + '<div class="item-title">' + item.name + '</div>' + '</div>' + '<div class="item-subtitle item-price"></div>' + '<div class="padding-top">' + '<div class="stepper stepper-raised stepper-small stepper-init">' + '<div class="stepper-button-minus disminuirCantidadItem" data-id-item="' + item.id + '"></div>' + '<div class="stepper-input-wrap">' + '<input type="number" value="' + item.quantity + '" min="1" max="100" step="1" readonly>' + '</div>' + '<div class="stepper-button-plus aumentarCantidadItem" data-id-item="' + item.id + '"></div>' + '</div>' + '</div>' + '</div>' + '<div class="item-after">' + '<button class="button button-round color-red borrar-item-button" data-id-item="' + item.id + '">' + '<i class="icon material-icons md-only">delete</i>' + '<i class="icon f7-icons ios-only">trash</i>' + '</button>' + '</div>' + '</div>';
        $('.virtual-list-carrito').append(itemTemplate);

        if (item.imagen) {
          $('.virtual-list-carrito .item-custom-class-' + item.id).prepend('<div class="item-media"><img class="img-item" src="' + urlImagen + item.id + '/' + item.imagen + '">');
        }

        if (item.mostrar_precio) {
          if (tipoEnvio == 'en-el-lugar' && item.price) {
            $('.virtual-list-carrito [data-id-item="' + item.id + '"] .item-price').html('<b>$ ' + item.price + '<b>');
          }

          if (tipoEnvio == 'takeaway' && item.price_takeaway) {
            $('.virtual-list-carrito [data-id-item="' + item.id + '"] .item-price').html('<b>$ ' + item.price_takeaway + '<b>');
          }

          if (tipoEnvio == 'delivery' && item.price_delivery) {
            $('.virtual-list-carrito [data-id-item="' + item.id + '"] .item-price').html('<b>$ ' + item.price_delivery + '<b>');
          }

          if (tipoEnvio == 'personalizado' && item.price_personalizado) {
            $('.virtual-list-carrito [data-id-item="' + item.id + '"] .item-price').html('<b>$ ' + item.price_personalizado + '<b>');
          }
        }
      }
    },

    actualizarCarrito() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.listarItemsCarrito();
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      let establecimiento = JSON.parse(localStorage.getItem('_establecimiento'));
      let itemList = listItemEstablecimiento(idEstablecimiento);
      let cantidad_total = 0;

      for (let i = 0; i < itemList.length; i++) {
        const element = itemList[i].quantity;
        cantidad_total += element;
      }

      if (cantidad_total > 1) {
        $('.subnavbar-cart-title .cart-items-span').html(cantidad_total + " ítems");
      } else {
        $('.subnavbar-cart-title .cart-items-span').html(cantidad_total + " ítem");
      }

      if (establecimiento.configuracion.mostrar_precio_total) {
        $('.subnavbar-cart-title .cart-total-span').html(" / Total $ " + totalEstablecimiento(idEstablecimiento).toFixed(2));
      }
    },

    openToast(text) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      let message = text;
      var toastBottom = app.toast.create({
        text: message,
        closeTimeout: 3000,
        destroyOnClose: true,
        closeButton: true,
        closeButtonText: 'Cerrar'
      });
      toastBottom.open();
    },

    comprobarCarrito() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      let establecimiento = JSON.parse(localStorage.getItem('_establecimiento'));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      let itemList = listItemEstablecimiento(idEstablecimiento);

      if (itemList.length > 0) {
        if (establecimiento.configuracion.recibir_pedidos_whatsapp) {
          $('.confirmar-carrito-button').removeClass('disabled');
        }
      } else {
        $('.confirmar-carrito-button').addClass('disabled');
        $('.eliminar-carrito-button').addClass('disabled');
      }
    },

    logCart(option, id_item) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimientoParam = app.view.main.router.currentRoute.params.idEstablecimiento;
      let url = window.global.url + "carrito/log/" + option;
      let cart = {
        id_establecimiento: idEstablecimientoParam
      };

      if (id_item) {
        cart = {
          id_establecimiento: idEstablecimientoParam,
          id_item: id_item
        };
      }

      app.preloader.show();
      app.request.postJSON(url, function (data) {});
      app.preloader.hide();
    }

  },
  on: {
    pageBeforeIn() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.listarItemsCarrito();
      self.comprobarCarrito();
    },

    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimientoParam = app.view.main.router.currentRoute.params.idEstablecimiento;
      var idMesaParam = app.view.main.router.currentRoute.params.idMesa;
      let itemList = listItemEstablecimiento(idEstablecimientoParam);
      let establecimiento = JSON.parse(localStorage.getItem('_establecimiento'));
      $('.virtual-list-carrito').on('click', '.borrar-item-button', function () {
        var idItem = $(this).attr('data-id-item');
        remove(parseInt(idItem));
        self.actualizarCarrito();
        self.comprobarCarrito();
        self.openToast("Ítem eliminado del carrito.");
        self.logCart("remove", idItem);
      });
      $('.virtual-list-carrito').on('click', '.disminuirCantidadItem', function () {
        var idItem = $(this).attr('data-id-item');
        let item = get(parseInt(idItem));

        if (item.quantity > 1) {
          quantity(parseInt(idItem), -1);
          self.actualizarCarrito();
          self.logCart("decrease", idItem);
        }
      });
      $('.virtual-list-carrito').on('click', '.aumentarCantidadItem', function () {
        var idItem = $(this).attr('data-id-item');
        quantity(parseInt(idItem), +1);
        self.actualizarCarrito();
        self.logCart("increase", idItem);
      });
      $('.confirmar-carrito-button').on('click', function () {
        if (establecimiento.configuracion.recibir_pedidos_whatsapp) {
          app.views.main.router.navigate('pedido-form');
        }

        self.logCart("confirm", null);
      });
      $('.eliminar-carrito-button').on('click', function () {
        if (itemList.length > 0) {
          app.dialog.confirm('Al eliminar el carrito, se borrarán todos los ítems agregados.', '¿Eliminar carrito?', function () {
            destroy();
            self.actualizarCarrito();
            self.comprobarCarrito();
            self.openToast("Carrito eliminado.");
            self.logCart("delete", null);
          });
        }
      });
      let cantidad_total = 0;

      for (let i = 0; i < itemList.length; i++) {
        const element = itemList[i].quantity;
        cantidad_total += element;
      }

      if (cantidad_total > 1) {
        $('.subnavbar-cart-title .cart-items-span').html(cantidad_total + " ítems");
      } else {
        $('.subnavbar-cart-title .cart-items-span').html(cantidad_total + " ítem");
      }

      if (establecimiento.configuracion.mostrar_precio_total) {
        $('.subnavbar-cart-title .cart-total-span').html(" / Total $ " + totalEstablecimiento(idEstablecimientoParam).toFixed(2));
      }
    }

  },
  id: '7848abc2d9',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=cart><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Carrito</div><div class=right></div></div></div><!-- Subnavbar --><div class=subnavbar><div class=subnavbar-inner><span></span><div class="subnavbar-title subnavbar-cart-title"><span class=cart-items-span></span> <span class=cart-total-span></span></div><span></span></div></div><!-- Fixed Toolbar --><div class="toolbar toolbar-bottom"><div class=toolbar-inner><a class="button color-red button-fill eliminar-carrito-button">Eliminar carrito</a> <a class="button color-green button-fill confirmar-carrito-button disabled">Confirmar carrito</a></div></div><div class=page-content><div class="row justify-content-center"><div class="col-100 medium-100 large-50"><div class=block-header>Lista de ítems</div><div class="list virtual-list-carrito media-list no-chevron"></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
    .img-item {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 50%;
    }
    
    .ios {
        --f7-subnavbar-title-font-size: 24px;
    }
`,
  styleScoped: false
};