export default {
  methods: {
    crearNovedad() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var usuario = JSON.parse(localStorage.getItem('usuario'));
      var url = window.global.url + "novedades";
      var titulo = $('input[type=text][name="titulo"]').val().trim();
      var textEditor = app.textEditor.get('.novedad-text-editor');
      var contenido = textEditor.getValue();
      var estado = 1;

      if (!$('[name="estado-novedad-checkbox"]').is(':checked')) {
        estado = 0;
      }

      var novedad = {
        "titulo": titulo,
        "contenido": contenido,
        "estado": estado,
        "id_usuario": usuario.id_usuario
      };
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'POST',
        data: novedad,
        statusCode: {
          404: function (xhr) {
            console.log("URL not found");
          },
          400: function (xhr) {
            console.log("Bad request. Some of the inputs provided to the request aren't valid.");
          },
          401: function (xhr) {
            console.log("Not authenticated. The user session isn't valid.");
          },
          403: function (xhr) {
            // console.log("The user isn't authorized to perform the specified request.");
            app.views.main.router.navigate({
              path: '/'
            });
          },
          500: function (xhr) {
            console.log("Internal server error. Additional details will be contained on the server logs.");
          },
          201: function (xhr) {
            console.log("The requested resource has been created.");
          }
        },
        success: function (data, status, xhr) {
          // console.log(data);
          if (data.status == 'success') {
            app.dialog.alert("Novedad creada con éxito.");
            self.reiniciarCampos();
            self.validarCampos();
          } else {
            app.dialog.alert("Error al crear novedad.");
          }
        },
        error: function (xhr, status) {
          console.log(xhr);
          console.log(JSON.stringify(xhr));
          console.log(status);
          app.dialog.alert("Error al modificar novedad.");
        }
      });
    },

    reiniciarCampos() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      $('input[type=text][name="titulo"]').val("");
      var textEditor = app.textEditor.get('.novedad-text-editor');
      var contenido = textEditor.setValue("");
    },

    validarCampos() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var titulo = $('input[type=text][name="titulo"]').val().toLowerCase().trim();
      var textEditor = app.textEditor.get('.novedad-text-editor');
      var contenido = textEditor.getValue();

      if (titulo.length < 3) {
        $('.crear-novedad-button').addClass('disabled');
        return false;
      }

      if (contenido.length < 1) {
        $('.modificar-novedad-button').addClass('disabled');
        return false;
      }

      $('.crear-novedad-button').removeClass('disabled');
      return true;
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var textEditor = app.textEditor.create({
        el: '.novedad-text-editor'
      });
      $('.page[data-name="superadmin-novedad-form"] input[type=text][name=titulo], .novedad-text-editor').on('input', function () {
        self.validarCampos();
      });
      $('.page[data-name="superadmin-novedad-form"] .crear-novedad-button').on('click', function () {
        self.crearNovedad();
      });
    }

  },
  id: 'b509f1c076',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=superadmin-novedad-form><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Crear novedad</div><div class=right></div></div></div><div class=page-content><div class="row justify-content-center"><div class="col-100 medium-100 large-40"><div class=block-title>Novedad</div><div class="list no-hairlines-md"><ul><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Título</div><div class=item-input-wrap><input type=text name=titulo placeholder=Título maxlength=60 pattern=.{3,} required validate data-error-message="El nombre debe tener al menos 3 caracteres."></div></div></div></li><li><label class="item-checkbox item-content"><input type=checkbox name=estado-novedad-checkbox checked=checked required validate> <i class="icon icon-checkbox"></i><div class=item-inner><div class=item-title>Visible / No visible</div></div></label></li><div class="text-editor text-editor-resizable novedad-text-editor" data-placeholder=Contenido... data-buttons=\'[["bold", "italic", "underline", "strikeThrough"], ["orderedList", "unorderedList"]]\'><div class=text-editor-content contenteditable></div></div></ul></div><div class=block><p class=row><a href=# class="col button button-round button-fill crear-novedad-button disabled">Guardar Cambios</a></p></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};