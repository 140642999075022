export default {
  methods: {
    signIn() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var url = window.global.url + "auth/login";
      var email = $('input[type=email][name=email]').val().toLowerCase().trim();
      var password = $('input[name=password]').val().trim();
      let params = {
        email: email,
        password: password
      };
      app.preloader.show();
      app.request.postJSON(url, params, function (data) {
        if (data.status == 'success') {
          localStorage.setItem("token", data.token);
          self.getUser(data.token);
        } else {
          app.preloader.hide();
          app.dialog.alert("E-mail o contraseña incorrectos.");
        }

        app.preloader.hide();
      }, function (xhr, status) {
        console.log(xhr);
        console.log(status);
        app.preloader.hide();
        app.dialog.alert("E-mail o contraseña incorrectos.");
      });
    },

    getUser(token) {
      var self = this;
      var app = self.$app;
      var $ = self.$; // var url = window.global.url + "auth/user/" + token;

      var url = window.global.url + "auth/user";
      app.preloader.show();
      app.request.postJSON(url, {
        token: token
      }, function (data) {
        // console.log(data);
        if (data.status == 'success') {
          app.preloader.hide();
          localStorage.setItem("usuario", JSON.stringify(data.usuario));

          if (data.usuario.rol == 'administrador') {
            // Si el usuario tiene más de 1 establecimiento
            if (data.usuario.establecimientos.length > 1) {
              // Navegar a Lista de Establecimientos de Usuario
              app.views.main.router.navigate({
                name: 'admin-establecimientos-list',
                params: {
                  idEstablecimiento: data.usuario.id_usuario
                }
              });
            } // Si el usuario tiene sólo 1 establecimiento
            else {
                // Navegar a Establecimiento
                app.views.main.router.navigate({
                  name: 'admin-principal',
                  params: {
                    idEstablecimiento: data.usuario.establecimientos[0].id_establecimiento
                  }
                });
              }

            app.preloader.hide();
          }

          if (data.usuario.rol == "superadmin") {
            app.views.main.router.navigate('superadmin');
          }
        } else {
          app.preloader.hide();
          app.dialog.alert(data.message);
          app.dialog.alert("Error general.");
        }
      }, function (xhr, status) {
        app.preloader.hide();
        console.log(xhr);
        console.log(status);
        app.dialog.alert("Error general.x");
      });
    },

    validarCampos() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var email = $('input[type=email][name=email]').val().toLowerCase();
      var password = $('input[name=password]').val();

      if (!email || email.length < 3 || $('input[type=email][name=email]').hasClass('input-invalid')) {
        $('.signIn-button').addClass('disabled');
        return false;
      }

      if (!password || password.length < 6) {
        $('.signIn-button').addClass('disabled');
        return false;
      }

      $('.signIn-button').removeClass('disabled');
      return true;
    },

    togglePasswordVisibility() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      let passwordInput = $('input[name=password]');

      if (passwordInput.attr('type') === 'password') {
        passwordInput.attr('type', 'text');
        $('.toggle-password .material-icons').html('visibility');
        $('.toggle-password .f7-icons').html('eye');
      } else {
        passwordInput.attr('type', 'password');
        $('.toggle-password .material-icons').html('visibility_off');
        $('.toggle-password .f7-icons').html('eye_slash');
      }
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      $("input[type=email][name=email], input[name=password]").on('input', function () {
        self.validarCampos();
      });
      $('.signIn-button').on('click', function () {
        self.signIn();
      });
      $('.toggle-password').on('click', function () {
        self.togglePasswordVisibility();
      });
    }

  },
  id: 'fe0a36ee72',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-navbar no-toolbar no-swipeback login-screen-page"><div class="page-content login-screen-content"><div class="text-align-center no-margin no-padding"><img class=logo src=static/wm-logo.png><div class="login-screen-title no-margin-top no-padding-top">Webmenú</div></div><form class=login-form><div class=list><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">E-mail</div><div class=item-input-wrap><input type=email name=email placeholder=E-mail pattern=[a-z-A-Z-0-9._%+-]+@[a-z-A-Z-0-9.-]+\.[a-z-A-Z-]{2,}$ required validate data-error-message="Ingresar un e-mail válido"> <span class=input-clear-button></span></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">Contraseña</div><div class=item-input-wrap><input type=password name=password placeholder=Contraseña pattern=.{6,} required validate data-error-message="La contraseña debe tener al menos 6 caracteres"></div></div><div><div class="item-media toggle-password"><button type=button class="button button-round color-gray md-only"><i class="icon material-icons">visibility_off</i></button> <button type=button class="button button-round color-gray ios-only"><i class="icon f7-icons">eye_slash</i></button></div></div></li></ul></div><div class=list><ul><li><a href=# class="list-button signIn-button disabled">Ingresar</a></li></ul><div class=block-footer>Visitá <a href=https://webmenu.com.ar class="link external" target=_blank>Webmenú</a> para más información.</div></div></form></div></div>';
      return r;
    }(this);
  },

  style: `
    .logo {
        max-width: 200px;
    }
`,
  styleScoped: false
};