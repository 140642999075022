import { listItemEstablecimiento } from '../js/cart-sessionstorage/cart-sessionstorage.js';
export default {
  methods: {
    truncate(str, n, useWordBoundary) {
      if (str.length <= n) {
        return str;
      }

      const subString = str.substr(0, n - 1); // the original check

      return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) : subString) + "&hellip;";
    },

    getCategoria() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idCategoria = app.view.main.router.currentRoute.params.idCategoria;
      var url = window.global.url + "categorias/" + idCategoria;
      var urlImagen = window.global.url + "imagen/categoria/" + idCategoria + "/";
      app.request.getJSON(url, function (data) {
        if (data.status == 'success') {
          var categoria = data.categoria;
          $('.title-categoria').text(categoria.nombre);
          $('.descripcion-categoria').html(categoria.descripcion);
        } else {
          app.dialog.alert("Error al obtener categoría.");
        }
      }, function (xhr, status) {
        console.log(xhr);
        console.log(status);
        app.dialog.alert("Error al obtener categoría");
      });
    },

    getItems() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimientoParam = app.view.main.router.currentRoute.params.idEstablecimiento;
      var idMesaParam = app.view.main.router.currentRoute.params.idMesa;
      var idCategoria = app.view.main.router.currentRoute.params.idCategoria;
      var nombreCategoria = app.view.main.router.currentRoute.params.nombreCategoria;
      var tipoEnvio = sessionStorage.getItem('_tipoenvio');
      var url = window.global.url + "items/habilitados/establecimiento/" + idEstablecimientoParam + "/categoria/" + idCategoria;
      var urlImagen = window.global.url + 'imagen/item/';
      app.request.json(url, function (response) {
        if (response.code == 200) {
          $('.title-categoria').text(response.nombre_categoria);

          for (let i = 0; i < response.categorias.length; i++) {
            const categorias = response.categorias[i];

            if (categorias.id_categoria != idCategoria) {
              $('.vl-items').append(`<a href="#" class="accordion-list custom-accordion" data-id-categoria="` + categorias.id_categoria + `">
                                                  <div class="accordion-item">
                                                  <div class="accordion-item-toggle">
                                                  <div class="card elevation-3 margin-bottom text-color-black">
                                                  <div class="card-content card-content-padding">
                                                  <div class="row">
                                                  <div class="col-50 align-items-flex-start">
                                                  <div>
                                                  <h3>` + categorias.nombre + `</h3>
                                                  </div>
                                                  </div>
                                                  </div>
                                                  </div>
                                                  </div>
                                                  </div>
                                                  </div>
                                                  </a>`);
            }

            for (let j = 0; j < categorias.items.length; j++) {
              const item = categorias.items[j];

              if (item.id_categoria != idCategoria) {
                // Con imagen
                if (item.imagen) {
                  $('.vl-items [data-id-categoria="' + item.id_categoria + '"] .accordion-item').append(`<a class="item" data-id-item="` + item.id_item + `" href="` + item.id_item + `">
                                                          <div class="accordion-item-content">                
                                                          <div class="card elevation-3 margin-bottom text-color-black">
                                                          <div class="card-content card-content-padding">
                                                          <div class="row">
                                                          <div class="col align-items-flex-start">
                                                          <div class="display-flex">
                                                          <img src="` + urlImagen + ` ` + item.id_item + `/` + item.imagen + `" class="align-self-center rounded-border margin-right" width="100px" height="100px">
                                                          <div class="align-self-center">
                                                          <p class="text-nombre">` + item.nombre + `</p>
    
                                                          <p class="text-descripcion text-color-gray"></p>
                                                          <p class="text-precio"></p>
                                                          </div>
                                                          </div>
                                                          </div>
                                                          </div>
                                                          </div>
                                                          </div>
                                                          </div>
                                                          </a>`);
                } // Sin imagen
                else {
                    $('.vl-items [data-id-categoria="' + item.id_categoria + '"] .accordion-item').append(`
                                    <a class="item" data-id-item="` + item.id_item + `" href="` + item.id_item + `/">
                                    <div class="accordion-item-content">
                                    <div class="card elevation-3 margin-bottom text-color-black">
                                    <div class="card-content card-content-padding">
                                    <div class="row">
                                    <div class="col-50 align-items-flex-start">
                                    <div>
                                    <p class="text-nombre">` + item.nombre + `</p>
                                    <p class="text-descripcion text-color-gray"></p>
                                    </div>
                                    </div>
                                    <div class="col-50 align-self-center">
                                    <div class="text-precio text-align-right"></div>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    </a>
                                    `);
                  }
              } else {
                // Con imagen
                if (item.imagen) {
                  $('.vl-items').append(`<a class="item" data-id-item="` + item.id_item + `" href="` + item.id_item + `">
                                                          <div class="card elevation-3 margin-bottom text-color-black">
                                                          <div class="card-content card-content-padding">
                                                          <div class="row">
                                                          <div class="col align-items-flex-start">
                                                          <div class="display-flex">
                                                          <img src="` + urlImagen + ` ` + item.id_item + `/` + item.imagen + `" class="align-self-center rounded-border margin-right" width="100px" height="100px">
                                                          <div class="align-self-center">
                                                          <p class="text-nombre">` + item.nombre + `</p>
    
                                                          <p class="text-descripcion text-color-gray"></p>
                                                          <p class="text-precio"></p>
                                                          </div>
                                                          </div>
                                                          </div>
                                                          </div>
                                                          </div>
                                                          </div>
                                                          </a>`);
                } // Sin imagen
                else {
                    $('.vl-items').append(`
                                    <a class="item" data-id-item="` + item.id_item + `" href="` + item.id_item + `/">
                                    <div class="card elevation-3 margin-bottom text-color-black">
                                    <div class="card-content card-content-padding">
                                    <div class="row">
                                    <div class="col-50 align-items-flex-start">
                                    <div>
                                    <p class="text-nombre">` + item.nombre + `</p>
                                    <p class="text-descripcion text-color-gray"></p>
                                    </div>
                                    </div>
                                    <div class="col-50 align-self-center">
                                    <div class="text-precio text-align-right"></div>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    </a>
                                    `);
                  }
              }

              if (item.mostrar_precio) {
                if (tipoEnvio == 'en-el-lugar' && item.precio) {
                  $('a[data-id-item="' + item.id_item + '"] .text-precio').html("<b>$ " + item.precio + "</b>");
                } else if (tipoEnvio == 'takeaway' && item.precio_takeaway) {
                  $('a[data-id-item="' + item.id_item + '"] .text-precio').html("<b>$ " + item.precio_takeaway + "</b>");
                } else if (tipoEnvio == 'delivery' && item.precio_delivery) {
                  $('a[data-id-item="' + item.id_item + '"] .text-precio').html("<b>$ " + item.precio_delivery + "</b>");
                } else if (tipoEnvio == 'personalizado' && item.precio_envio_personalizado) {
                  $('a[data-id-item="' + item.id_item + '"] .text-precio').html("<b>$ " + item.precio_envio_personalizado + "</b>");
                }
              }

              if (item.descripcion) {
                item.descripcion = self.truncate(item.descripcion, 54, 1);
                $('a[data-id-item="' + item.id_item + '"] .text-descripcion').html(item.descripcion);
              }
            }
          }
        }

        app.preloader.hide();
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getCategoria();
      self.getItems();
      $('.cart-fab').off("click");
    }

  },
  id: '84f3374dd8',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=items><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class="title title-categoria"></div><div class=right></div><div class=subnavbar><form data-search-container=.vl-items data-search-item=a data-search-in=.text-nombre class="searchbar searchbar-init"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=Buscar> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancelar</span></div></form></div></div></div><div class=searchbar-backdrop></div><div class=page-content><div class="row justify-content-center"><div class="col-100 medium-100 large-50"><div class=block><p class="descripcion-categoria searchbar-found"></p></div><div class="list simple-list no-hairlines searchbar-not-found"><ul><li>No hay resultados.</li></ul></div><div class="list vl-items searchbar-found"></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
    img {
        object-fit: cover;
    }
`,
  styleScoped: false
};