export default {
  methods: {
    changePassword() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idUsuario = JSON.parse(localStorage.getItem('usuario')).id_usuario;
      var token = localStorage.getItem('token');
      var url = window.global.url + "password/change";
      var password = $('.page[data-name="superadmin-change-password"] input[name="password"]').val().trim();
      var usuario = {
        token: token,
        password: password
      };
      app.preloader.show();
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'PUT',
        data: usuario,
        statusCode: {
          404: function (xhr) {
            console.log("URL not found");
          },
          400: function (xhr) {
            console.log("Bad request. Some of the inputs provided to the request aren't valid.");
          },
          401: function (xhr) {
            console.log("Not authenticated. The user session isn't valid.");
          },
          403: function (xhr) {
            console.log("The user isn't authorized to perform the specified request.");
            app.views.main.router.navigate({
              path: '/'
            });
          },
          500: function (xhr) {
            console.log("Internal server error. Additional details will be contained on the server logs.");
          },
          201: function (xhr) {
            console.log("The requested resource has been created.");
          }
        },
        success: function (data, status, xhr) {
          // console.log(data);
          if (data.status == 'success') {
            app.dialog.alert("Contraseña modificada con éxito.");
            localStorage.setItem('token', data.token);
          } else {
            app.dialog.alert("Error al modificar contraseña.");
          }

          app.preloader.hide();
        },
        error: function (xhr, status) {
          app.preloader.hide();
          console.log(xhr); // console.log(JSON.stringify(xhr));

          console.log(status);
          app.dialog.alert("Error al modificar contraseña.");
        }
      });
    },

    validarCampos() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var password = $('.page[data-name="superadmin-change-password"] input[name="password"]').val();

      if (password < 6) {
        $('.guardar-cambios-button').addClass('disabled');
        return false;
      }

      $('.guardar-cambios-button').removeClass('disabled');
      return true;
    },

    togglePasswordVisibility() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      let passwordInput = $('.page[data-name="superadmin-change-password"] input[name="password"]');

      if (passwordInput.attr('type') === 'password') {
        passwordInput.attr('type', 'text');
        $('.toggle-password .material-icons').html('visibility');
        $('.toggle-password .f7-icons').html('eye');
      } else {
        passwordInput.attr('type', 'password');
        $('.toggle-password .material-icons').html('visibility_off');
        $('.toggle-password .f7-icons').html('eye_slash');
      }
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      $('.page[data-name="superadmin-change-password"] input[name="password"]').on('input', function () {
        self.validarCampos();
      });
      $('.guardar-cambios-button').on('click', function () {
        self.changePassword();
      });
      $('.page[data-name="superadmin-change-password"] .toggle-password').on('click', function () {
        self.togglePasswordVisibility();
      });
    }

  },
  id: '8edca79238',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=superadmin-change-password><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Cambiar contraseña</div><div class=right></div></div></div><div class=page-content><div class="list no-hairlines-md"><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">Nueva contraseña</div><div class=item-input-wrap><input type=password name=password placeholder=Contraseña required validate pattern=.{6,} required validate data-error-message="La contraseña debe tener al menos 6 caracteres"></div></div><div><div class="item-media toggle-password"><button type=button class="button button-round color-gray md-only"><i class="icon material-icons">visibility_off</i></button> <button type=button class="button button-round color-gray ios-only"><i class="icon f7-icons">eye_slash</i></button></div></div></li></ul></div><div class=block><p class=row><a href=# class="col button button-round button-fill guardar-cambios-button disabled">Guardar Cambios</a></p></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};