import LoginPage from '../pages/auth/login.f7.html';
// import RegistroPage from '../pages/auth/login.f7.html';

import TiposEnvioPage from '../pages/tipos-envio.f7.html';
import CartaPage from '../pages/carta.f7.html';
import CartaNoDisponiblePage from '../pages/carta-no-disponible.f7.html';
import ItemsPage from '../pages/items.f7.html';
import ItemDetallePage from '../pages/item-detalle.f7.html';
import CartPage from '../pages/cart.f7.html';
import PedidoFormPage from '../pages/pedido-form.f7.html';
import SearchResultsPage from '../pages/search-results.f7.html';
import PopupInfoPage from '../pages/popup-info.f7.html';
import PopupCalificacionPage from '../pages/popup-calificacion.f7.html';

// Superadmin
import SuperAdminPrincipalPage from '../pages/superadmin/superadmin-principal.f7.html';
import SuperAdminMisDatosPage from '../pages/superadmin/mis-datos/superadmin-mis-datos.f7.html';
import SuperAdminChangePasswordPage from '../pages/superadmin/mis-datos/superadmin-change-password.f7.html';

import SuperAdminEstablecimientosList from '../pages/superadmin/establecimientos/superadmin-establecimientos-list.f7.html';
import SuperAdminEstablecimientoForm from '../pages/superadmin/establecimientos/superadmin-establecimiento-form.f7.html';
import SuperAdminEstablecimientoMod from '../pages/superadmin/establecimientos/superadmin-establecimiento-mod.f7.html';
import SuperAdminUsuariosList from '../pages/superadmin/usuarios/superadmin-usuarios-list.f7.html';
import SuperAdminUsuarioForm from '../pages/superadmin/usuarios/superadmin-usuario-form.f7.html';
import SuperAdminUsuarioMod from '../pages/superadmin/usuarios/superadmin-usuario-mod.f7.html';
import SuperAdminCajaPage from '../pages/superadmin/caja/superadmin-caja.f7.html';
import SuperAdminNovedadesListPage from '../pages/superadmin/novedades/superadmin-novedades-list.f7.html';
import SuperAdminNovedadFormPage from '../pages/superadmin/novedades/superadmin-novedad-form.f7.html';
import SuperAdminNovedadModPage from '../pages/superadmin/novedades/superadmin-novedad-mod.f7.html';

// Admin
import AdminPrincipalPage from '../pages/admin/admin-principal.f7.html';
import AdminEstablecimientosListPage from '../pages/admin/admin-establecimientos-list.f7.html';

import AdminCartaPage from '../pages/admin/carta/admin-carta.f7.html';
import AdminItemsPage from '../pages/admin/carta/admin-items.f7.html';
import AdminItemDetallePage from '../pages/admin/carta/admin-item-detalle.f7.html';

import AdminMisDatos from '../pages/admin/mis-datos/mis-datos.f7.html';
import AdminChangePasswordPage from '../pages/admin/mis-datos/admin-change-password.f7.html';
import AdminEstablecimientoMod from '../pages/admin/mis-datos/establecimiento-mod.f7.html';
import AdminImagenMod from '../pages/admin/mis-datos/imagen-mod.f7.html';
import AdminHorariosMod from '../pages/admin/mis-datos/horarios-mod.f7.html';
import AdminRedesSocialesMod from '../pages/admin/mis-datos/redes-sociales-mod.f7.html';
import AdminMensajeMod from '../pages/admin/mis-datos/mensaje-mod.f7.html';

import AdminItemsListPage from '../pages/admin/items/admin-items-list.f7.html';
import AdminItemFormPage from '../pages/admin/items/admin-item-form.f7.html';
import AdminItemModPage from '../pages/admin/items/admin-item-mod.f7.html';

import AdminCategoriasListPage from '../pages/admin/categorias/admin-categorias-list.f7.html';
import AdminCategoriaFormPage from '../pages/admin/categorias/admin-categoria-form.f7.html';
import AdminCategoriaModPage from '../pages/admin/categorias/admin-categoria-mod.f7.html';

import AdminSubcategoriasPage from '../pages/admin/categorias/subcategorias/admin-subcategorias-list.f7.html';
import AdminSubcategoriaFormPage from '../pages/admin/categorias/subcategorias/admin-subcategoria-form.f7.html';
import AdminSubcategoriaModPage from '../pages/admin/categorias/subcategorias/admin-subcategoria-mod.f7.html';

import AdminConfiguracion from '../pages/admin/configuracion/configuracion.f7.html';
import AdminTipoMesaModPage from '../pages/admin/configuracion/tipomesa-mod.f7.html';
import AdminMostrarTotalModPage from '../pages/admin/configuracion/mostrartotal-mod.f7.html';
import AdminPedidosWhatsappModPage from '../pages/admin/configuracion/pedidoswhatsapp-mod.f7.html';
import AdminVistaClasicaCategoriasModPage from '../pages/admin/configuracion/vistaclasicacategorias-mod.f7.html';
import AdminTiposEnvioModPage from '../pages/admin/configuracion/tiposenvio-mod.f7.html';
import AdminDesactivarMenuPage from '../pages/admin/configuracion/desactivar-menu.f7.html';
import AdminDesactivarCarritoPage from '../pages/admin/configuracion/desactivar-carrito.f7.html';
import AdminOcultarNombreCategoriasPage from '../pages/admin/configuracion/ocultar-nombre-categorias.f7.html';
import AdminPublicarEnWebPage from '../pages/admin/configuracion/publicar-en-web.f7.html';
import AdminOcultarNombresDestacadosPage from '../pages/admin/configuracion/ocultar-nombres-destacados.f7.html';
import AdminOcultarDireccionPage from '../pages/admin/configuracion/ocultar-direccion.f7.html';
import AdminOcultarHorariosPage from '../pages/admin/configuracion/ocultar-horarios.f7.html';

import AdminOrdenarCategoriasPage from '../pages/admin/categorias/admin-ordenar-categorias.f7.html';
import AdminOrdenarItemsPage from '../pages/admin/items/admin-ordenar-items.f7.html';
import AdminNovedadesPage from '../pages/admin/novedades/novedades.f7.html';
import AdminNovedadDetallePage from '../pages/admin/novedades/novedad-detalle.f7.html';

import AdminCalificacionesPage from '../pages/admin/calificaciones/admin-calificaciones.f7.html';
import AdminCalificacionesPositivasPage from '../pages/admin/calificaciones/admin-calificaciones-positivas.f7.html';
import AdminCalificacionesNegativasPage from '../pages/admin/calificaciones/admin-calificaciones-negativas.f7.html';

import NotFoundPage from '../pages/404.f7.html';

var routes = [{
        path: '/',
        component: LoginPage,
    },
    // Superadmin
    {
        path: '/superadmin',
        name: 'superadmin-principal',
        component: SuperAdminPrincipalPage,
    },
    {
        path: '/mis-datos',
        name: 'mis-datos',
        component: SuperAdminMisDatosPage,
        routes: [{
            path: 'change-password',
            name: 'change-password',
            component: SuperAdminChangePasswordPage,

        }]
    },
    {
        path: '/establecimientos-list',
        name: 'establecimientos-list',
        component: SuperAdminEstablecimientosList,
        routes: [{
            path: 'form',
            name: 'establecimiento-form',
            component: SuperAdminEstablecimientoForm,
        }, {
            path: 'mod/:idEstablecimiento',
            name: 'establecimiento-mod',
            component: SuperAdminEstablecimientoMod,
        }]
    },
    {
        path: '/usuarios-list',
        name: 'usuarios-list',
        component: SuperAdminUsuariosList,
        routes: [{
            path: 'form',
            name: 'usuario-form',
            component: SuperAdminUsuarioForm,
        }, {
            path: 'mod/:idUsuario',
            name: 'usuario-mod',
            component: SuperAdminUsuarioMod,
        }]
    },
    {
        path: '/caja',
        name: 'superadmin-caja',
        component: SuperAdminCajaPage,
    },
    {
        path: '/novedades',
        name: 'superadmin-novedades',
        component: SuperAdminNovedadesListPage,
        routes: [{
                path: 'form',
                name: 'superadmin-novedad-form',
                component: SuperAdminNovedadFormPage,
            },
            {
                path: 'mod/:idNovedad',
                name: 'superadmin-novedad-mod',
                component: SuperAdminNovedadModPage,
            },
        ]
    },
    // Admin
    {
        path: '/admin/establecimientos-list',
        name: 'admin-establecimientos-list',
        component: AdminEstablecimientosListPage
    },
    {
        path: '/admin/establecimiento/:idEstablecimiento',
        name: 'admin-principal',
        component: AdminPrincipalPage,
        routes: [{
                path: 'novedades',
                name: 'admin-novedades',
                component: AdminNovedadesPage,
                routes: [{
                    path: ':idNovedad',
                    name: 'admin-novedad-detalle',
                    component: AdminNovedadDetallePage
                }]
            }, {
                path: 'calificaciones',
                name: 'admin-calificaciones',
                component: AdminCalificacionesPage,
                routes: [{
                    path: 'calificaciones-positivas',
                    name: 'calificaciones-positivas',
                    component: AdminCalificacionesPositivasPage
                }, {
                    path: 'calificaciones-negativas',
                    name: 'calificaciones-negativas',
                    component: AdminCalificacionesNegativasPage
                }]
            },
            {
                path: 'carta',
                name: 'admin-carta',
                component: AdminCartaPage,
                routes: [{
                        path: 'categoria/:idCategoria',
                        name: 'admin-items',
                        component: AdminItemsPage,
                    },
                    {
                        path: 'categoria/:idCategoria/item/:idItem',
                        name: 'admin-item-detalle',
                        component: AdminItemDetallePage,
                    },
                    {
                        path: 'item/:idItem',
                        name: 'admin-item-detalle',
                        component: AdminItemDetallePage,
                    },
                ]
            },
            {
                path: 'mis-datos',
                name: 'mis-datos',
                component: AdminMisDatos,
                routes: [{
                        path: 'establecimiento-mod',
                        name: 'establecimiento-mod',
                        component: AdminEstablecimientoMod,
                    },
                    {
                        path: 'imagen-mod',
                        name: 'imagen-mod',
                        component: AdminImagenMod,
                    },
                    {
                        path: 'horarios-mod',
                        name: 'horarios-mod',
                        component: AdminHorariosMod,
                    },
                    {
                        path: 'redes-sociales-mod',
                        name: 'redes-sociales-mod',
                        component: AdminRedesSocialesMod,
                    },
                    {
                        path: 'mensaje-mod',
                        name: 'mensaje-mod',
                        component: AdminMensajeMod,
                    },
                    {
                        path: 'change-password',
                        name: 'change-password',
                        component: AdminChangePasswordPage,
                    },

                ]
            },
            {
                path: 'configuracion',
                name: 'configuracion',
                component: AdminConfiguracion,
                routes: [{
                        path: 'tipomesa-mod',
                        name: 'tipomesa-mod',
                        component: AdminTipoMesaModPage,
                    },
                    {
                        path: 'mostrartotal-mod',
                        name: 'mostrartotal-mod',
                        component: AdminMostrarTotalModPage,
                    },
                    {
                        path: 'pedidoswhatsapp-mod',
                        name: 'pedidoswhatsapp-mod',
                        component: AdminPedidosWhatsappModPage,
                    },
                    {
                        path: 'vistaclasicacategorias-mod',
                        name: 'vistaclasicacategorias-mod',
                        component: AdminVistaClasicaCategoriasModPage,
                        AdminTiposEnvioModPage
                    },
                    {
                        path: 'tiposenvio-mod',
                        name: 'tiposenvio-mod',
                        component: AdminTiposEnvioModPage
                    },
                    {
                        path: 'desactivar-menu',
                        name: 'desactivar-menu',
                        component: AdminDesactivarMenuPage
                    },
                    {
                        path: 'desactivar-carrito',
                        name: 'desactivar-carrito',
                        component: AdminDesactivarCarritoPage
                    },
                    {
                        path: 'ocultar-nombre-categorias',
                        name: 'ocultar-nombre-categorias',
                        component: AdminOcultarNombreCategoriasPage
                    },
                    {
                        path: 'publicar-en-web',
                        name: 'publicar-en-web',
                        component: AdminPublicarEnWebPage
                    },
                    {
                        path: 'ocultar-nombres-destacados',
                        name: 'ocultar-nombres-destacados',
                        component: AdminOcultarNombresDestacadosPage
                    },
                    {
                        path: 'ocultar-direccion',
                        name: 'ocultar-direccion',
                        component: AdminOcultarDireccionPage
                    },
                    {
                        path: 'ocultar-horarios',
                        name: 'ocultar-horarios',
                        component: AdminOcultarHorariosPage
                    }
                ]
            },
            {
                path: 'categorias/list',
                name: 'admin-categorias-list',
                component: AdminCategoriasListPage,
                routes: [{
                        path: 'form',
                        name: 'admin-categoria-form',
                        component: AdminCategoriaFormPage,
                    },
                    {
                        path: 'mod/:idCategoria',
                        name: 'admin-categoria-mod',
                        component: AdminCategoriaModPage
                    },
                ]
            },
            {
                path: 'subcategorias/list',
                name: 'admin-subcategorias-list',
                component: AdminSubcategoriasPage,
                routes: [{
                        path: 'form',
                        name: 'admin-subcategoria-form',
                        component: AdminSubcategoriaFormPage,
                    },
                    {
                        path: 'mod/:idSubcategoria',
                        name: 'admin-subcategoria-mod',
                        component: AdminSubcategoriaModPage
                    },
                ]
            },
            {
                path: 'items/list',
                name: 'admin-items-list',
                component: AdminItemsListPage,
                routes: [{
                        path: 'form',
                        name: 'admin-item-form',
                        component: AdminItemFormPage,
                    },
                    {
                        path: 'mod/:idItem',
                        name: 'admin-item-mod',
                        component: AdminItemModPage,
                    },
                ]
            },
            {
                path: 'ordenar-categorias',
                name: 'admin-ordenar-categorias',
                component: AdminOrdenarCategoriasPage,
                routes: [{
                    path: ':idCategoria/ordenar-items',
                    name: 'admin-ordenar-items',
                    component: AdminOrdenarItemsPage
                }]
            },
        ]
    },


    // Menú
    {
        path: '/:idEstablecimiento/:idMesa',
        name: 'seleccionar-tipo-envio',
        component: TiposEnvioPage,
        routes: [{
                path: '/carta',
                name: 'principal-menu',
                component: CartaPage,
                routes: [{
                        path: '/popup-info',
                        name: 'popup-info',
                        component: PopupInfoPage
                    }, {
                        path: '/popup-calificacion',
                        name: 'popup-calificacion',
                        component: PopupCalificacionPage
                    },
                    {
                        path: 'categoria/:idCategoria/items/',
                        name: 'items',
                        component: ItemsPage,
                        routes: [{
                                path: ':idItem',
                                name: 'item-detalle',
                                component: ItemDetallePage,
                                routes: [{
                                    path: 'cart',
                                    name: 'cart',
                                    component: CartPage,
                                    routes: [{
                                        path: 'pedido-form',
                                        name: 'pedido-form',
                                        component: PedidoFormPage,
                                    }]
                                }]
                            },
                            {
                                path: 'cart',
                                name: 'cart',
                                component: CartPage,
                                routes: [{
                                    path: 'pedido-form',
                                    name: 'pedido-form',
                                    component: PedidoFormPage,
                                }]
                            }
                        ]
                    },
                    {
                        path: 'item/:idItem',
                        name: 'item-detalle',
                        component: ItemDetallePage,
                        routes: [{
                            path: 'cart',
                            name: 'cart',
                            component: CartPage,
                            routes: [{
                                path: 'pedido-form',
                                name: 'pedido-form',
                                component: PedidoFormPage,
                            }]
                        }]
                    },
                    {
                        path: 'query/:query',
                        name: 'search-results',
                        component: SearchResultsPage,
                        routes: [{
                            path: 'cart',
                            name: 'cart',
                            component: CartPage,
                            routes: [{
                                path: 'pedido-form',
                                name: 'pedido-form',
                                component: PedidoFormPage,
                            }]
                        }]
                    },
                    {
                        path: 'cart',
                        name: 'cart',
                        component: CartPage,
                        routes: [{
                            path: 'pedido-form',
                            name: 'pedido-form',
                            component: PedidoFormPage,
                        }]
                    }
                ]
            },
            {
                path: '/carta-no-disponible',
                name: 'carta-no-disponible',
                component: CartaNoDisponiblePage
            }
        ]
    },

    {
        path: '(.*)',
        component: NotFoundPage,
    },
];

export default routes;