export default {
  methods: {
    getEstablecimiento() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var identity = JSON.parse(localStorage.getItem("usuario"));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var url = window.global.url + "establecimientos/" + idEstablecimiento;
      app.preloader.show();
      app.request.json(url, function (response) {
        console.log(response);

        if (response.code == 200) {
          var establecimiento = response.establecimiento;
          $('.page[data-name="mensaje-mod"] textarea[name="mensaje"]').val(establecimiento.mensaje);
          app.input.resizeTextarea('.page[data-name="mensaje-mod"] textarea[name="mensaje"]');
          app.preloader.hide();
        } else {
          app.preloader.hide();
          app.dialog.alert("Error");
        }
      }, function (xhr, status) {
        app.preloader.hide();
        console.log(xhr);
        console.log(status);
        app.dialog.alert("Error general");
      });
    },

    modificarMensaje() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var identity = JSON.parse(localStorage.getItem("usuario"));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var url = window.global.url + "establecimientos/mensaje/" + idEstablecimiento;
      var mensajeInput = $('.page[data-name="mensaje-mod"] textarea[name="mensaje"]').val().trim();
      var mensajeObject = {
        mensaje: mensajeInput
      };
      app.preloader.show();
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'PUT',
        data: mensajeObject,
        statusCode: {
          404: function (xhr) {
            console.log("URL not found");
          },
          400: function (xhr) {
            console.log("Bad request. Some of the inputs provided to the request aren't valid.");
          },
          401: function (xhr) {
            console.log("Not authenticated. The user session isn't valid.");
          },
          403: function (xhr) {
            // console.log("The user isn't authorized to perform the specified request.");
            app.views.main.router.navigate({
              path: '/'
            });
          },
          500: function (xhr) {
            console.log("Internal server error. Additional details will be contained on the server logs.");
          },
          201: function (xhr) {
            console.log("The requested resource has been created.");
          }
        },
        success: function (data, status, xhr) {
          // console.log(data);
          if (data.status == 'success') {
            app.preloader.hide();
            app.dialog.alert("Mensaje modificado con éxito.");
          } else {
            app.preloader.hide();
            app.dialog.alert("Error al modificar mensaje.");
          }
        },
        error: function (xhr, status) {
          app.preloader.hide();
          console.log(xhr);
          console.log(JSON.stringify(xhr));
          console.log(status);
          app.dialog.alert("Error al modificar mensaje.");
        }
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getEstablecimiento();
      $('.page[data-name="mensaje-mod"] .button-guardar-cambios').on('click', function () {
        self.modificarMensaje();
      });
    }

  },
  id: '43b78e469b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=mensaje-mod><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class="title sliding">Editar mensaje</div><div class=right></div></div></div><!-- Scrollable page content--><div class=page-content><div class=block><p>Agregá un mensaje o anuncio para que tus clientes lo puedan ver en la carta.</p></div><div class="list no-hairlines-md"><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">Mensaje</div><div class=item-input-wrap><textarea class=resizable name=mensaje placeholder="Ejemplo: Hoy cena y show a partir de las 20 hs." maxlength=100></textarea> <span class=input-clear-button></span></div></div></li></ul><div class=block><button class="col button button-guardar-cambios button-fill button-round">Guardar cambios</button></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};