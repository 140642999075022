export default {
  methods: {
    getEstablecimiento() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var identity = JSON.parse(localStorage.getItem("usuario"));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var url = window.global.url + "establecimientos/" + idEstablecimiento;
      app.preloader.show();
      app.request.json(url, function (response) {
        if (response.code == 200) {
          var establecimiento = response.establecimiento;
          sessionStorage.setItem('_establecimiento', JSON.stringify(establecimiento));
          app.preloader.hide();
        } else {
          app.preloader.hide();
          app.dialog.alert("Error");
        }
      }, function (xhr, status) {
        app.preloader.hide();
        console.log(xhr);
        console.log(status);
        app.dialog.alert("Error general");
      });
    }

  },
  on: {
    pageBeforeIn() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      $('.nombre-usuario').text(JSON.parse(localStorage.getItem('usuario')).nombre);
    },

    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$; // self.getEstablecimiento();
    }

  },
  id: '0a140210bb',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=superadmin-principal><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><!-- <a href="#" class="link icon-only panel-open" data-panel="left">\r\n            <i class="icon f7-icons if-not-md">menu</i>\r\n            <i class="icon material-icons if-md">menu</i>\r\n          </a> --></div><div class=title>Webmenú</div><div class=right></div></div></div><!-- Scrollable page content--><div class=page-content><!-- <div class="block block-strong">\r\n        <p>Here is your blank Framework7 app. Let\'s see what we have here.</p>\r\n      </div> --><div class="row justify-content-center"><div class="col-100 medium-100 large-50"><div class="block no-margin-bottom no-padding-bottom"><p>¡Hola, <span class=nombre-usuario></span>!</p></div></div></div><div class="block block-strong"><div class="row justify-content-center"><p class="col-100 medium-50"><a href=/mis-datos class="button button-raised button-fill"><i class="icon fas fa-user"></i> Mis datos</a></p></div><div class="row justify-content-center"><p class="col-100 medium-50"><a href=/establecimientos-list class="button button-raised button-fill"><i class="icon fas fa-store-alt"></i> Establecimientos</a></p></div><div class="row justify-content-center"><p class="col-100 medium-50"><a href=/usuarios-list class="button button-raised button-fill"><i class="icon fas fa-users"></i> Usuarios</a></p></div><div class="row justify-content-center"><p class="col-100 medium-50"><a href=/caja class="button button-raised button-fill"><i class="icon fas fa-cash-register"></i> Caja</a></p></div><div class="row justify-content-center"><p class="col-100 medium-50"><a href=/novedades class="button button-raised button-fill"><i class="icon fas fa-newspaper"></i> Novedades</a></p></div></div></div></div>';
      return r;
    }(this);
  },

  style: `

`,
  styleScoped: false
};