export default {
  methods: {
    truncate(str, n, useWordBoundary) {
      if (str.length <= n) {
        return str;
      }

      const subString = str.substr(0, n - 1); // the original check

      return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) : subString) + "&hellip;";
    },

    formatDate(fecha) {
      var date = new Date(fecha);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();

      if (day < 10) {
        day = '0' + day;
      }

      if (month < 10) {
        month = '0' + month;
      }

      var formattedDate = day + '/' + month + '/' + year;
      return formattedDate;
    },

    getNovedad() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var usuario = JSON.parse(localStorage.getItem('usuario'));
      var idNovedad = app.view.main.router.currentRoute.params.idNovedad;
      var url = window.global.url + "novedades/" + idNovedad;
      app.preloader.show();
      app.request.json(url, function (response) {
        if (response.code == 200) {
          const novedad = response.novedad;
          $('.page[data-name="admin-novedad-detalle"] .novedad-titulo').html('<h1>' + novedad.titulo + '</h1>');
          $('.page[data-name="admin-novedad-detalle"] .novedad-contenido').html(novedad.contenido);
          $('.page[data-name="admin-novedad-detalle"] .date').html('Publicado el ' + self.formatDate(novedad.created_at));
        }

        app.preloader.hide();
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getNovedad();
    }

  },
  id: '03f20571fc',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=admin-novedad-detalle><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Novedades</div><div class=right></div></div></div><div class=page-content><div class="row justify-content-center"><div class="col-100 medium-100 large-50"><div class=card><div class="card-header text-color-white novedad-titulo"></div><div class="card-content card-content-padding"><p class=date></p><p class=novedad-contenido></p></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
    img {
        object-fit: cover;
    }
    
    .card .card-content-padding .date {
        color: #8e8e93;
    }
    
    .card .card-header {
        /* background: #9796f0; */
        /* background: -webkit-linear-gradient(to left, #fbc7d4, #9796f0); */
        /* background: linear-gradient(to left, #fbc7d4, #9796f0); */
        background: #FF5F6D;
        background: -webkit-linear-gradient(to left, #FFC371, #FF5F6D);
        background: linear-gradient(to left, #FFC371, #FF5F6D);
    }
`,
  styleScoped: false
};