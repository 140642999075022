import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './routes.js';

// Import main app component
import App from '../app.f7.html';

var app = new Framework7({
    root: '#app', // App root element
    component: App, // App main component
    id: 'com.webmenu', // App bundle ID
    name: 'Webmenú', // App name
    theme: 'auto', // Automatic theme detection



    // App routes
    routes: routes,

    view: {
        pushState: true,
        // pushStateRoot: document.location.pathname.split('index.html')[0],
        // pushStateSeparator: '',
        // pushStateSeparator: '/#!',
    },


    // Input settings
    input: {
        scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
        scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
    },
    dialog: {
        // set default title for all dialog shortcuts
        buttonCancel: 'Cancelar',
    },
    on: {
        init: function () {
            var f7 = this;
            if (f7.device.cordova) {
                // Init cordova APIs (see cordova-app.js)
                cordovaApp.init(f7);
            }
        },
    },
});

window.global = {
    // url: "http://170.78.75.137:85/api/",
    // url: "http://127.0.0.1:8000/api/"
    url: "https://neocortex.com.ar/api/",
};