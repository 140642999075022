export default {
  methods: {
    getEstablecimiento() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var identity = JSON.parse(localStorage.getItem("usuario"));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var url = window.global.url + "establecimientos/" + idEstablecimiento;
      app.preloader.show();
      app.request.json(url, function (response) {
        if (response.code == 200) {
          var establecimiento = response.establecimiento;
          sessionStorage.setItem('_establecimiento', JSON.stringify(establecimiento));
          $('.page[data-name="desactivar-carrito"] input[name="desactivar-carrito-en-el-lugar"]').prop('checked', establecimiento.configuracion.desactivar_carrito_en_el_lugar);
          $('.page[data-name="desactivar-carrito"] input[name="desactivar-carrito-takeaway"]').prop('checked', establecimiento.configuracion.desactivar_carrito_takeaway);
          $('.page[data-name="desactivar-carrito"] input[name="desactivar-carrito-delivery"]').prop('checked', establecimiento.configuracion.desactivar_carrito_delivery);
          $('.page[data-name="desactivar-carrito"] input[name="desactivar-carrito-envio-personalizado"]').prop('checked', establecimiento.configuracion.desactivar_carrito_envio_personalizado);
          app.preloader.hide();
        } else {
          app.preloader.hide();
          app.dialog.alert("Error");
        }
      }, function (xhr, status) {
        app.preloader.hide();
        console.log(xhr);
        console.log(status);
        app.dialog.alert("Error general");
      });
    },

    modificarDesactivarCarrito() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var identity = JSON.parse(localStorage.getItem("usuario"));
      var establecimiento = JSON.parse(sessionStorage.getItem("_establecimiento"));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var url = window.global.url + "configuracion/establecimiento/" + idEstablecimiento;
      var toggleDesactivarCarritoEnElLugar = app.toggle.get('.toggle-desactivar-carrito-en-el-lugar');
      var toggleDesactivarCarritoTakeaway = app.toggle.get('.toggle-desactivar-carrito-takeaway');
      var toggleDesactivarCarritoDelivery = app.toggle.get('.toggle-desactivar-carrito-delivery');
      var toggleDesactivarCarritoEnvioPersonalizado = app.toggle.get('.toggle-desactivar-carrito-envio-personalizado');
      let desactivarCarritoEnElLugar = 0;
      let desactivarCarritoTakeaway = 0;
      let desactivarCarritoDelivery = 0;
      let desactivarCarritoEnvioPersonalizado = 0;

      if (toggleDesactivarCarritoEnElLugar.checked) {
        desactivarCarritoEnElLugar = 1;
      }

      if (toggleDesactivarCarritoTakeaway.checked) {
        desactivarCarritoTakeaway = 1;
      }

      if (toggleDesactivarCarritoDelivery.checked) {
        desactivarCarritoDelivery = 1;
      }

      if (toggleDesactivarCarritoEnvioPersonalizado.checked) {
        desactivarCarritoEnvioPersonalizado = 1;
      }

      var configuracionObject = {
        "desactivar_carrito_en_el_lugar": desactivarCarritoEnElLugar,
        "desactivar_carrito_takeaway": desactivarCarritoTakeaway,
        "desactivar_carrito_delivery": desactivarCarritoDelivery,
        "desactivar_carrito_envio_personalizado": desactivarCarritoEnvioPersonalizado
      };
      app.preloader.show();
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'PUT',
        data: configuracionObject,
        statusCode: {
          404: function (xhr) {
            console.log("URL not found");
          },
          400: function (xhr) {
            console.log("Bad request. Some of the inputs provided to the request aren't valid.");
          },
          401: function (xhr) {
            console.log("Not authenticated. The user session isn't valid.");
          },
          403: function (xhr) {
            // console.log("The user isn't authorized to perform the specified request.");
            app.views.main.router.navigate({
              path: '/'
            });
          },
          500: function (xhr) {
            console.log("Internal server error. Additional details will be contained on the server logs.");
          },
          201: function (xhr) {
            console.log("The requested resource has been created.");
          }
        },
        success: function (data, status, xhr) {
          // console.log(data);
          if (data.status == 'success') {
            app.preloader.hide();
            app.dialog.alert("Configuración guardada con éxito.");
          } else {
            app.preloader.hide();
            app.dialog.alert("Error al guardar configuración.");
          }
        },
        error: function (xhr, status) {
          app.preloader.hide();
          console.log(xhr);
          console.log(JSON.stringify(xhr));
          console.log(status);
          app.dialog.alert("Error al guardar configuración.");
        }
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var toggleDesactivarCarritoEnElLugar = app.toggle.create({
        el: '.toggle-desactivar-carrito-en-el-lugar'
      });
      var toggleDesactivarCarritoTakeaway = app.toggle.create({
        el: '.toggle-desactivar-carrito-takeaway'
      });
      var toggleDesactivarCarritoDelivery = app.toggle.create({
        el: '.toggle-desactivar-carrito-delivery'
      });
      var toggleDesactivarCarritoEnvioPersonalizado = app.toggle.create({
        el: '.toggle-desactivar-carrito-envio-personalizado'
      });
      self.getEstablecimiento();
      $('.page[data-name="desactivar-carrito"] .button-guardar-cambios').on('click', function () {
        self.modificarDesactivarCarrito();
      });
    }

  },
  id: '090a3a121a',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=desactivar-carrito><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class="title sliding">Desactivar carrito</div><div class=right></div></div></div><!-- Scrollable page content--><div class=page-content><div class=block><p>Desactiva el carrito en tu menú. Si está desactivado tus clientes no podrán realizar pedidos online.</p></div><div class="list no-hairlines-md"><ul><li class=item-content><div class=item-inner><div class=item-title>Desactivar carrito para el salón</div><div class=item-after><label class="toggle toggle-desactivar-carrito-en-el-lugar"><input type=checkbox name=desactivar-carrito-en-el-lugar> <span class=toggle-icon></span></label></div></div></li><li class=item-content><div class=item-inner><div class=item-title>Desactivar carrito para take away</div><div class=item-after><label class="toggle toggle-desactivar-carrito-takeaway"><input type=checkbox name=desactivar-carrito-takeaway> <span class=toggle-icon></span></label></div></div></li><li class=item-content><div class=item-inner><div class=item-title>Desactivar carrito para delivery</div><div class=item-after><label class="toggle toggle-desactivar-carrito-delivery"><input type=checkbox name=desactivar-carrito-delivery> <span class=toggle-icon></span></label></div></div></li><li class=item-content><div class=item-inner><div class=item-title>Desactivar carrito para envío personalizado</div><div class=item-after><label class="toggle toggle-desactivar-carrito-envio-personalizado"><input type=checkbox name=desactivar-carrito-envio-personalizado> <span class=toggle-icon></span></label></div></div></li></ul><div class=block><button class="col button button-guardar-cambios button-fill button-round">Guardar cambios</button></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};