export default {
  methods: {
    getItems() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var usuario = JSON.parse(localStorage.getItem('usuario'));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var idCategoria = app.view.main.router.currentRoute.params.idCategoria;
      var url = window.global.url + 'items/establecimiento/' + idEstablecimiento + '/categoria/' + idCategoria;
      app.request.json(url, function (response) {
        if (response.code == 200) {
          let items = response.items; // console.log(categorias);

          var virtualList = app.virtualList.create({
            // List Element
            el: '.virtual-list-items',
            // Pass array with items
            items: items,
            // Custom search function for searchbar
            searchAll: function (query, items) {
              var found = [];
              let queryNormalized = query.normalize('NFD').replace(/[\u0300-\u036f]/g, "").normalize().toLowerCase().trim();

              for (var i = 0; i < items.length; i++) {
                if (items[i].nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(queryNormalized) >= 0 || query.trim() === '') found.push(i);
              }

              return found; //return array with mathced indexes
            },
            // List item Template7 template
            itemTemplate: '<li id="{{id_item}}">' + '<a href="" class="item-link item-content">' + '<div class="item-inner">' + '<div class="item-title-row">' + '<div class="item-title">{{nombre}}</div>' + '</div>' + '{{#if estado}}' + '<div class="item-subtitle">Visible</div>' + '{{else}}' + '<div class="item-subtitle">Oculto</div>' + '{{/if}}' + '</div>' + '</a>' + '<div class="sortable-handler"></div>' + '</li>',
            // Item height
            height: app.theme === 'ios' ? 63 : app.theme === 'md' ? 73 : 46
          });
        }
      });
    },

    guardarOrdenItems(ordenItems) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var url = window.global.url + "ordenar/items";
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'PUT',
        data: ordenItems,
        statusCode: {
          404: function (xhr) {
            console.log("URL not found");
          },
          400: function (xhr) {
            console.log("Bad request. Some of the inputs provided to the request aren't valid.");
          },
          401: function (xhr) {
            console.log("Not authenticated. The user session isn't valid.");
          },
          403: function (xhr) {
            console.log("The user isn't authorized to perform the specified request.");
            app.views.main.router.navigate({
              path: '/'
            });
          },
          500: function (xhr) {
            console.log("Internal server error. Additional details will be contained on the server logs.");
          },
          201: function (xhr) {
            console.log("The requested resource has been created.");
          }
        },
        success: function (data, status, xhr) {
          // console.log(data);
          if (data.status == 'success') {
            app.dialog.alert("Productos ordenados con éxito.");
          } else {
            app.dialog.alert("Error al ordenar los productos.");
          }
        },
        error: function (xhr, status) {
          console.log(xhr);
          console.log(JSON.stringify(xhr));
          console.log(status);
          app.dialog.alert("Error al ordenar los productos.");
        }
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getItems(); // app.on('sortableSort', function(listEl, indexes) {
      // let ordenCategorias = [];
      // $('\.virtual-list-items li').each(function() {
      //     let id = $(this).attr('id');
      //     let index = $(this).index() + 1;
      //     ordenCategorias.push({
      //         id_categoria: id,
      //         orden: index
      //     })
      // });
      // console.log(JSON.stringify(ordenCategorias));
      // });

      $('.button-guardar-orden').on("click", function () {
        let ordenItems = [];
        $('\.virtual-list-items li').each(function () {
          let id = $(this).attr('id');
          let index = $(this).index() + 1;
          ordenItems.push({
            id_item: id,
            orden: index
          });
        });
        self.guardarOrdenItems(ordenItems);
      });
    }

  },
  id: 'fb0234f8d7',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=admin-ordenar-items><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Ordenar productos</div><div class=right><!-- Link to toggle sortable --> <a class="link sortable-toggle" data-sortable=.sortable>Ordenar</a></div><div class=subnavbar><form data-search-container=.virtual-list-items data-search-item=li data-search-in=.item-title class="searchbar searchbar-init"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Buscar productos"> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancelar</span></div></form></div></div></div><div class=searchbar-backdrop></div><!-- Scrollable page content--><div class=page-content><div class="list simple-list searchbar-not-found"><ul><li>No se encontraron resultados.</li></ul></div><div class="block searchbar-hide-on-search"><p>Presione el botón "Ordenar" en la esquina superior derecha para ordenar los productos.</p><div class=row><p class=col><a href=# class="button button-guardar-orden button-raised button-fill">Guardar cambios</a></p></div></div><div class="list sortable virtual-list virtual-list-items media-list searchbar-found"></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};