export default {
  methods: {
    getCategorias() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var url = window.global.url + "categorias/superiores/establecimiento/" + idEstablecimiento;
      app.preloader.show();
      app.request.json(url, function (response) {
        if (response.status == 'success') {
          var categorias = response.categorias;
          categorias.forEach(function (categoria, i) {
            $('[name="categoria-superior"]').append($('<option>').text(categoria.nombre).attr('value', categoria.id_categoria));
          }); // $('[name="categoria-superior"] option[value="0"]').prop('selected', true);
        }

        app.preloader.hide();
      });
    },

    crearCategoria() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var url = window.global.url + "subcategorias/";
      var usuario = JSON.parse(localStorage.getItem('usuario'));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var nombre = $('input[type=text][name=nombre]').val().trim();
      var idCategoriaSuperior = $('[name="categoria-superior"]').val();
      var estado = 1;
      var file = $('input[name=file]')[0].files[0];

      if (!$('[name="estado-categoria-checkbox"]').is(':checked')) {
        estado = 0;
      }

      let categoria = {
        id_usuario: usuario.id_usuario,
        id_establecimiento: idEstablecimiento,
        id_padre: idCategoriaSuperior,
        nombre: nombre,
        estado: estado
      };
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'POST',
        data: categoria,
        statusCode: {
          404: function (xhr) {
            console.log("URL not found");
          },
          400: function (xhr) {
            console.log("Bad request. Some of the inputs provided to the request aren't valid.");
          },
          401: function (xhr) {
            console.log("Not authenticated. The user session isn't valid.");
          },
          403: function (xhr) {
            console.log("The user isn't authorized to perform the specified request.");
            app.views.main.router.navigate({
              path: '/'
            });
          },
          500: function (xhr) {
            console.log("Internal server error. Additional details will be contained on the server logs.");
          },
          201: function (xhr) {
            console.log("The requested resource has been created.");
          }
        },
        success: function (data, status, xhr) {
          // console.log(data);
          if (data.status == 'success') {
            // console.log(data);
            if (file) {
              self.uploadImage(data.categoria.id_categoria);
            }

            app.dialog.alert("Categoría creada con éxito");
            self.reiniciarCampos();
            self.validarCampos();
            app.views.main.router.refreshPage();
          } else {
            app.dialog.alert("Error al crear la categoría.");
          }
        },
        error: function (xhr, status) {
          console.log(xhr);
          console.log(JSON.stringify(xhr));
          console.log(status);
          app.dialog.alert("Error al crear la categoría.");
        }
      });
    },

    uploadImage(idCategoria) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var url = window.global.url + "upload/imagen/categoria/" + idCategoria;
      var file = $('input[name=file]')[0].files[0];

      if (file) {
        let fileData = new FormData();
        fileData.append('file', file);
        app.preloader.show();
        app.request({
          headers: {
            'Authorization': localStorage.getItem('token')
          },
          url: url,
          dataType: 'file',
          contentType: false,
          method: 'POST',
          data: fileData,
          statusCode: {
            404: function (xhr) {
              console.log("URL not found");
            },
            400: function (xhr) {
              console.log("Bad request. Some of the inputs provided to the request aren't valid.");
            },
            401: function (xhr) {
              console.log("Not authenticated. The user session isn't valid.");
            },
            403: function (xhr) {
              console.log("The user isn't authorized to perform the specified request.");
              app.views.main.router.navigate({
                path: '/'
              });
            },
            500: function (xhr) {
              console.log("Internal server error. Additional details will be contained on the server logs.");
            },
            201: function (xhr) {
              console.log("The requested resource has been created.");
            }
          },
          success: function (data, status, xhr) {
            data = JSON.parse(data);

            if (data.status == 'success') {
              app.preloader.hide();
            } else {
              app.preloader.hide();
              app.dialog.alert("Error al subir imagen.");
            }
          },
          error: function (xhr, status) {
            app.preloader.hide();
            console.log(xhr);
            console.log(JSON.stringify(xhr));
            console.log(status);
            app.dialog.alert("Error al subir imagen.");
          }
        });
      } else {
        app.dialog.alert("No hay imagen seleccionada.");
      }
    },

    validarCampos() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var nombre = $('input[type=text][name=nombre]').val().toLowerCase().trim();

      if (nombre.length < 3) {
        $('.crear-categoria-button').addClass('disabled');
        return false;
      }

      $('.crear-categoria-button').removeClass('disabled');
      return true;
    },

    reiniciarCampos() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      $('input[type=text][name=nombre]').val("");
      $('input[name=file]').val("");
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      self.getCategorias();
      $('.crear-categoria-button').on('click', function () {
        self.crearCategoria();
      });
      $('.page[data-name="admin-subcategoria-form"] .button-subir-imagen').on('click', function () {
        $('[name="file"]').click();
      });
      $("input[type=text][name=nombre]").on('input', function () {
        self.validarCampos();
      });
      $('.page[data-name="admin-subcategoria-form"] .back-button').on('click', function () {
        app.views.main.router.navigate({
          name: 'admin-subcategorias-list',
          params: {
            idEstablecimiento: idEstablecimiento
          }
        });
      });
    }

  },
  id: '5cbed1b956',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=admin-subcategoria-form><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back-button"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Nueva subcategoría</div><div class=right></div></div></div><div class=page-content><div class=block-title>Subcategoría</div><div class="list no-hairlines-md"><ul><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Nombre</div><div class=item-input-wrap><input type=text name=nombre placeholder="Nombre de la subcategoría" pattern=.{3,} maxlength=100 required validate data-error-message="El nombre debe tener al menos 3 caracteres."></div></div></div></li><li><a class="item-link smart-select smart-select-init"><select name=categoria-superior></select><div class=item-content><div class=item-inner><div class=item-title>Categoría superior</div></div></div></a></li><li><label class="item-checkbox item-content"><input type=checkbox name=estado-categoria-checkbox checked=checked required validate> <i class="icon icon-checkbox"></i><div class=item-inner><div class=item-title>Mostrar/Ocultar subcategoría</div></div></label></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Subir imagen</div><div class=item-input-wrap><button class="button button-subir-imagen"><i class="icon f7-icons ios-only">camera</i> <i class="icon material-icons md-only">photo_camera</i> Subir imagen</button> <input hidden type=file name=file accept="image/png, image/jpeg"></div></div></div></li></ul></div><div class=block><p class=row><a href=# class="col button button-round button-fill crear-categoria-button disabled">Crear</a></p></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};