export default {
  methods: {
    formatDateArgentina(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear(),
          hours = d.getUTCHours(),
          minutes = d.getUTCMinutes();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      if (hours < 10) {
        hours = '0' + hours;
      }

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      return [day, month, year].join('/') + " a las " + [hours, minutes].join(':');
    },

    getCalificacionesPositivas() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      let idEstablecimiento = JSON.parse(sessionStorage.getItem('_establecimiento')).id_establecimiento;
      let url = window.global.url + "calificaciones/positivas/establecimiento/" + idEstablecimiento;
      app.preloader.show();
      $('.list-calificaciones').empty();
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'GET',
        success: function (response, status, xhr) {
          if (response.status == 'success') {
            let calificacionesPositivas = response.calificaciones;

            if (calificacionesPositivas.length == 0) {
              $('.list-calificaciones').append('<div class="text-align-center"><b>No se encontraron calificaciones en la fecha seleccionada.</b></div>');
            } else {
              calificacionesPositivas.forEach(calificacion => {
                let calificacionTemplate = `<div class="card elevation-4">
                              <div class="card-content card-content-padding">
                                <div class="row">
                                  <div class="col">
                                    <div><b>` + calificacion.nombre + `</b></div>
                                    <div><p>` + (calificacion.comentario ? calificacion.comentario : "") + `</p></div>
                                    <div class="text-color-gray">` + self.formatDateArgentina(calificacion.created_at) + `</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            `;
                $('.list-calificaciones').append(calificacionTemplate);
              });
            }
          } else {
            app.dialog.alert("Error.");
          }

          app.preloader.hide();
        },
        error: function (xhr, status) {
          app.preloader.hide();
          console.log(xhr);
          console.log(JSON.stringify(xhr));
          console.log(status);
          app.dialog.alert("Error.");
        }
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getCalificacionesPositivas();
    }

  },
  id: 'cddd7f306f',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Calificaciones positivas</div><div class=right></div></div></div><div class=page-content><div class="row justify-content-center"><div class="col-100 medium-60 large-40"><div class=block-title>Últimas calificaciones positivas</div><div class=list-calificaciones></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
    .emoji-icon {
        font-size: 60px;
    }
    
    .text-size-custom {
        font-size: 16px;
    }
`,
  styleScoped: false
};