export default {
  methods: {
    getItem() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var idItem = app.view.main.router.currentRoute.params.idItem;
      var url = window.global.url + "items/" + idItem;
      app.request.json(url, function (response) {
        // console.log(response)
        if (response.code == 200) {
          var item = response.item;
          var imageUrl = window.global.url + 'imagen/item/' + item.id_item + '/' + item.imagen;
          $('.item-title').text(item.nombre);

          if (item.imagen) {
            $('.card-item-destacado').addClass('demo-card-header-pic');
            $('.card-item-destacado .card-header').css('background-image', 'url(' + imageUrl + ')');
          } else {
            $('.card-item-destacado .item-title').removeClass('text-shadow');
            $('.card-item-destacado .item-title').addClass('text-color-black');
          }

          if (item.descripcion) {
            $('.card-item-destacado .item-descripcion').text(item.descripcion);
          }

          if (item.mostrar_precio) {
            $('.card-item-destacado .item-precio').text("$ " + item.precio);
          }
        }
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getItem();
    }

  },
  id: 'b6527f7d1a',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=admin-item-detalle><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class="title item-title"></div><div class=right></div></div></div><div class=page-content><div class=block><div class="row justify-content-center"><div class="col-100 medium-100 large-50"><div class="card card-item-destacado"><div class="card-header align-items-flex-end"><h2 class="item-title text-shadow"></h2></div><div class="card-content card-content-padding"><p class=item-descripcion></p><b><span class=item-precio></span></b></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};