export default {
  methods: {
    getEstablecimiento() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var identity = JSON.parse(localStorage.getItem("usuario"));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var url = window.global.url + "establecimientos/" + idEstablecimiento;
      app.preloader.show();
      app.request.json(url, function (response) {
        if (response.code == 200) {
          var establecimiento = response.establecimiento;
          sessionStorage.setItem('_establecimiento', JSON.stringify(establecimiento));
          $('.page[data-name="desactivar-menu"] textarea[name="mensaje"]').val(establecimiento.configuracion.mensaje_menu_no_disponible);
          $('.page[data-name="desactivar-menu"] input[name="desactivar-menu"]').prop('checked', establecimiento.configuracion.desactivar_menu);
          app.preloader.hide();
        } else {
          app.preloader.hide();
          app.dialog.alert("Error");
        }
      }, function (xhr, status) {
        app.preloader.hide();
        console.log(xhr);
        console.log(status);
        app.dialog.alert("Error general");
      });
    },

    modificarDesactivarMenu() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var identity = JSON.parse(localStorage.getItem("usuario"));
      var establecimiento = JSON.parse(sessionStorage.getItem("_establecimiento"));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var url = window.global.url + "configuracion/establecimiento/" + idEstablecimiento;
      var toggleDesactivarMenu = app.toggle.get('.toggle-desactivar-menu');
      var mensajeMenuNoDisponibleInput = $('.page[data-name="desactivar-menu"] textarea[name="mensaje"]').val().trim();
      let desactivarMenu = 0;

      if (toggleDesactivarMenu.checked) {
        desactivarMenu = 1;
      }

      var configuracionObject = {
        desactivar_menu: desactivarMenu,
        mensaje_menu_no_disponible: mensajeMenuNoDisponibleInput
      };
      app.preloader.show();
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'PUT',
        data: configuracionObject,
        statusCode: {
          404: function (xhr) {
            console.log("URL not found");
          },
          400: function (xhr) {
            console.log("Bad request. Some of the inputs provided to the request aren't valid.");
          },
          401: function (xhr) {
            console.log("Not authenticated. The user session isn't valid.");
          },
          403: function (xhr) {
            // console.log("The user isn't authorized to perform the specified request.");
            app.views.main.router.navigate({
              path: '/'
            });
          },
          500: function (xhr) {
            console.log("Internal server error. Additional details will be contained on the server logs.");
          },
          201: function (xhr) {
            console.log("The requested resource has been created.");
          }
        },
        success: function (data, status, xhr) {
          // console.log(data);
          if (data.status == 'success') {
            app.preloader.hide();
            app.dialog.alert("Configuración guardada con éxito.");
          } else {
            app.preloader.hide();
            app.dialog.alert("Error al guardar configuración.");
          }
        },
        error: function (xhr, status) {
          app.preloader.hide();
          console.log(xhr);
          console.log(JSON.stringify(xhr));
          console.log(status);
          app.dialog.alert("Error al guardar configuración.");
        }
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var toggleDesactivarMenu = app.toggle.create({
        el: '.toggle-desactivar-menu'
      });
      self.getEstablecimiento();
      $('.page[data-name="desactivar-menu"] .button-guardar-cambios').on('click', function () {
        self.modificarDesactivarMenu();
      });
    }

  },
  id: '34b8b52887',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=desactivar-menu><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class="title sliding">Desactivar menú</div><div class=right></div></div></div><!-- Scrollable page content--><div class=page-content><div class=block><p>En caso de que tu comercio se encuentre cerrado durante un período de tiempo, podés desactivar el menú y dejar un mensaje para que tus clientes lo vean.</p></div><div class="list no-hairlines-md"><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">Mensaje</div><div class=item-input-wrap><textarea class=resizable name=mensaje placeholder="Ejemplo: Cerrado por vacaciones" maxlength=100></textarea> <span class=input-clear-button></span></div></div></li><li class=item-content><div class=item-inner><div class=item-title>Desactivar menú</div><div class=item-after><label class="toggle toggle-desactivar-menu"><input type=checkbox name=desactivar-menu> <span class=toggle-icon></span></label></div></div></li></ul><div class=block><button class="col button button-guardar-cambios button-fill button-round">Guardar cambios</button></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};