export default {
  methods: {
    getTotalCalificaciones() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      let idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      let url = window.global.url + "calificaciones/total/establecimiento/" + idEstablecimiento;
      app.preloader.show();
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'GET',
        success: function (response, status, xhr) {
          // console.log(data);
          if (response.status == 'success') {
            let totalCalificacionesPositivas = response.total_calificaciones_positivas;
            let totalCalificacionesNegativas = response.total_calificaciones_negativas;
            $('.total-calificaciones-positivas').text(totalCalificacionesPositivas);
            $('.total-calificaciones-negativas').text(totalCalificacionesNegativas);
          } else {
            app.dialog.alert("Error.");
          }

          app.preloader.hide();
        },
        error: function (xhr, status) {
          app.preloader.hide();
          console.log(xhr);
          console.log(JSON.stringify(xhr));
          console.log(status);
          app.dialog.alert("Error.");
        }
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getTotalCalificaciones();
    }

  },
  id: '3d708597b7',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Calificaciones</div><div class=right></div></div></div><div class=page-content><div class=block><div class="row justify-content-center"><div class="col-100 medium-60 large-30"><div class=row><div class=col><a href=calificaciones-positivas><div class="card elevation-4 card-bg-positive text-color-white"><div class="card-content card-content-padding"><div class=row><div class="col align-self-center"><i class="icon emoji-icon far fa-laugh-beam"></i></div><div class=col><h1 class=total-calificaciones-positivas>0</h1><p class=text-size-custom>Positivas</p></div></div></div></div></a></div></div><div class=row><div class=col><a href=calificaciones-negativas><div class="card elevation-4 card-bg-negative text-color-white"><div class="card-content card-content-padding"><div class=row><div class="col align-self-center"><i class="icon emoji-icon far fa-frown"></i></div><div class=col><h1 class=total-calificaciones-negativas>0</h1><p class=text-size-custom>Negativas</p></div></div></div></div></a></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
    .emoji-icon {
        font-size: 60px;
    }
    
    .text-size-custom {
        font-size: 16px;
    }
    
    .card-bg-positive {
        background-color: #39F3BB;
        background-image: linear-gradient(-225deg, #F9FEA5 0%, #20E2D7 100%);
    }
    
    .card-bg-negative {
        background-color: #FF719A;
        background-image: linear-gradient(-225deg, #FFE29F 0%, #FFA99F 48%, #FF719A 100%);
    }
`,
  styleScoped: false
};