export default {
  methods: {
    truncate(str, n, useWordBoundary) {
      if (str.length <= n) {
        return str;
      }

      const subString = str.substr(0, n - 1); // the original check

      return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) : subString) + "&hellip;";
    },

    getSearchResults(queryParam) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimientoParam = app.view.main.router.currentRoute.params.idEstablecimiento;
      var idMesaParam = app.view.main.router.currentRoute.params.idMesa;
      let tipoEnvio = sessionStorage.getItem('_tipoenvio');
      var url = window.global.url + "search";
      var urlImagen = window.global.url + 'imagen/item/';
      let query = {
        "id_establecimiento": idEstablecimientoParam,
        "query": queryParam
      };
      app.preloader.show();
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'POST',
        data: query,
        statusCode: {
          404: function (xhr) {
            console.log("URL not found");
          },
          400: function (xhr) {
            console.log("Bad request. Some of the inputs provided to the request aren't valid.");
          },
          401: function (xhr) {
            console.log("Not authenticated. The user session isn't valid.");
          },
          403: function (xhr) {
            console.log("The user isn't authorized to perform the specified request.");
            app.views.main.router.navigate({
              path: '/'
            });
          },
          500: function (xhr) {
            console.log("Internal server error. Additional details will be contained on the server logs.");
          },
          201: function (xhr) {
            console.log("The requested resource has been created.");
          }
        },
        success: function (response, status, xhr) {
          // console.log(data);
          if (response.code == 200) {
            if (response.items.length == 0) {
              $('.no-results-list').removeClass("display-none");
            } else {
              $('.no-results-list').addClass("display-none");

              for (let j = 0; j < response.items.length; j++) {
                const item = response.items[j]; // Con imagen

                if (item.imagen) {
                  $('.vl-items').append(`<a class="item" data-id-item="` + item.id_item + `" href="/` + idEstablecimientoParam + `/` + idMesaParam + `/carta/item/` + item.id_item + `">
                                                            <div class="card elevation-3 margin-bottom text-color-black">
                                                            <div class="card-content card-content-padding">
                                                            <div class="row">
                                                            <div class="col align-items-flex-start">
                                                            <div class="display-flex">
                                                            <img src="` + urlImagen + ` ` + item.id_item + `/` + item.imagen + `" class="align-self-center rounded-border margin-right" width="100px" height="100px">
                                                            <div class="align-self-center">
                                                            <p class="text-nombre">` + item.nombre + `</p>
      
                                                            <p class="text-descripcion text-color-gray"></p>
                                                            <p class="text-precio"></p>
                                                            </div>
                                                            </div>
                                                            </div>
                                                            </div>
                                                            </div>
                                                            </div>
                                                            </a>`);
                } // Sin imagen
                else {
                    $('.vl-items').append(`
                                            <a class="item" data-id-item="` + item.id_item + `" href="/` + idEstablecimientoParam + `/` + idMesaParam + `/carta/item/` + item.id_item + `">
                                            <div class="card elevation-3 margin-bottom text-color-black">
                                            <div class="card-content card-content-padding">
                                            <div class="row">
                                            <div class="col-50 align-items-flex-start">
                                            <div>
                                            <p class="text-nombre">` + item.nombre + `</p>
                                            <p class="text-descripcion text-color-gray"></p>
                                            </div>
                                            </div>
                                            <div class="col-50 align-self-center">
                                            <div class="text-precio text-align-right"></div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </a>`);
                  }

                if (item.mostrar_precio && tipoEnvio == 'en-el-lugar') {
                  $('a[data-id-item="' + item.id_item + '"] .text-precio').html("<b>$ " + item.precio + "</b>");
                } else if (item.mostrar_precio && tipoEnvio == 'takeaway') {
                  $('a[data-id-item="' + item.id_item + '"] .text-precio').html("<b>$ " + item.precio_takeaway + "</b>");
                } else if (item.mostrar_precio && tipoEnvio == 'delivery') {
                  $('a[data-id-item="' + item.id_item + '"] .text-precio').html("<b>$ " + item.precio_takeaway + "</b>");
                }

                if (item.descripcion) {
                  item.descripcion = self.truncate(item.descripcion, 54, 1);
                  $('a[data-id-item="' + item.id_item + '"] .text-descripcion').html(item.descripcion);
                }
              }
            }
          }

          app.preloader.hide();
        },
        error: function (xhr, status) {
          app.preloader.hide();
          console.log(xhr);
          console.log(JSON.stringify(xhr));
          console.log(status);
          app.dialog.alert("Error.");
        }
      });
    },

    buscar() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimientoParam = app.view.main.router.currentRoute.params.idEstablecimiento;
      var idMesaParam = app.view.main.router.currentRoute.params.idMesa;
      let query = $('.page[data-name="search-results"] .buscar-input').val();

      if (query !== "") {
        $('.vl-items').empty();
        self.getSearchResults(query);
      }
    }

  },
  on: {
    pageBeforeRemove() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      $('.vl-items').empty();
    },

    pageReInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var queryParam = app.view.main.router.currentRoute.params.query;
      $('.vl-items').empty();
      self.getSearchResults(queryParam);
      $('.cart-fab').off("click");
    },

    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var queryParam = app.view.main.router.currentRoute.params.query;

      if (queryParam) {
        self.getSearchResults(queryParam);
      }

      $('.page[data-name="search-results"] .buscar-button').on('click', function () {
        self.buscar();
      });
      $('.cart-fab').off("click");
    }

  },
  id: '9efa170c7d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=search-results><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Resultados de búsqueda</div><div class=right></div><div class=subnavbar><!-- Searchbar --><div class=searchbar><div class=searchbar-inner><div class=searchbar-input-wrap><input class=buscar-input value=';
      r += c(ctx_1.$route.params.query, ctx_1);
      r += ' type=search placeholder=Buscar> <i class=searchbar-icon></i> <span class="input-clear-button buscar-button"></span></div><span class="searchbar-disable-button if-not-aurora">Cancelar</span></div></div></div></div></div><div class=page-content><div class="row justify-content-center"><div class="col-100 medium-100 large-50"><div class=block><div class="list simple-list no-hairlines no-results-list display-none"><ul><li>No hay resultados.</li></ul></div><div class="list vl-items"></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
    img {
        object-fit: cover;
    }
`,
  styleScoped: false
};