export default {
  methods: {
    getEstablecimiento() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var identity = JSON.parse(localStorage.getItem("usuario"));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var url = window.global.url + "establecimientos/" + idEstablecimiento;
      var urlImagen = window.global.url + "imagen/establecimiento/" + idEstablecimiento + "/";
      app.preloader.show();
      app.request.json(url, function (response) {
        // console.log(response)
        if (response.code == 200) {
          var establecimiento = response.establecimiento;

          if (establecimiento.imagen) {
            urlImagen = $('.page[data-name="admin-imagen-mod"] .img-establecimiento').attr('src', urlImagen.concat(establecimiento.imagen));
          } else {
            urlImagen = $('.page[data-name="admin-imagen-mod"] .img-establecimiento').attr('src', 'static/image-placeholder.png');
          }

          app.preloader.hide();
        } else {
          app.preloader.hide();
          app.dialog.alert("Error");
        }
      }, function (xhr, status) {
        app.preloader.hide();
        console.log(xhr);
        console.log(status);
        app.dialog.alert("Error general");
      });
    },

    uploadImage() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var url = window.global.url + "upload/imagen/establecimiento/" + idEstablecimiento;
      var file = $('.page[data-name="admin-imagen-mod"] input[name=file]')[0].files[0];

      if (file) {
        let fileData = new FormData();
        fileData.append('file', file);
        app.preloader.show();
        app.request({
          headers: {
            'Authorization': localStorage.getItem('token')
          },
          url: url,
          dataType: 'file',
          contentType: false,
          method: 'POST',
          data: fileData,
          statusCode: {
            404: function (xhr) {
              console.log("URL not found");
            },
            400: function (xhr) {
              console.log("Bad request. Some of the inputs provided to the request aren't valid.");
            },
            401: function (xhr) {
              console.log("Not authenticated. The user session isn't valid.");
            },
            403: function (xhr) {
              // console.log("The user isn't authorized to perform the specified request.");
              app.views.main.router.navigate({
                path: '/'
              });
            },
            500: function (xhr) {
              console.log("Internal server error. Additional details will be contained on the server logs.");
            },
            201: function (xhr) {
              console.log("The requested resource has been created.");
            }
          },
          success: function (data, status, xhr) {
            data = JSON.parse(data);

            if (data.status == 'success') {
              app.preloader.hide();
            } else {
              app.preloader.hide();
              app.dialog.alert("Error al subir imagen.");
            }
          },
          error: function (xhr, status) {
            app.preloader.hide();
            console.log(xhr);
            console.log(JSON.stringify(xhr));
            console.log(status);
            app.dialog.alert("Error al subir imagen.");
          }
        });
      } else {
        app.preloader.hide();
      }
    },

    validarCampos() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var file = $('.page[data-name="admin-imagen-mod"] input[name=file]')[0].files[0];

      if (file) {
        $('.button-guardar-cambios').addClass('disabled');
        return false;
      }

      $('.button-guardar-cambios').removeClass('disabled');
      return true;
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var actionButtons = app.actions.create({
        buttons: [{
          text: 'Cambiar imagen',
          onClick: function () {
            $('[name="file"]').click();
          }
        }, {
          text: 'Cancelar',
          color: 'red'
        }]
      });
      self.getEstablecimiento();
      $('.page[data-name="admin-imagen-mod"] .button-cambiar-imagen').on('click', function () {
        $('[name="file"]').click();
      });
      $('.page[data-name="admin-imagen-mod"] .actionButtons').on('click', function () {
        actionButtons.open();
      });
      $('.page[data-name="admin-imagen-mod"] [name="file').on('change', function () {
        self.uploadImage();
      });
    }

  },
  id: '3200901b7b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=admin-imagen-mod><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class="title sliding">Editar imagen</div><div class=right></div></div></div><!-- Scrollable page content--><div class=page-content><div class="block block-strong"><p>Esta es la imagen o logo que será visible para tus clientes.</p><p>Una vez que subas la imagen, es posible que el cambio tarde unos minutos en actualizarse.</p><div class=block-footer>Tamaño recomendado: 1024 x 320 pixeles.</div><div class=block-footer>Formatos: jpg, png. Peso máximo: 2 MB.</div></div><div class="text-align-center actionButtons"><img class="img-border img-establecimiento" src="" alt=""><!-- <i class="icon icon-overlay material-icons md-only">photo_camera</i>\r\n            <i class="icon icon-overlay f7-icons ios-only">camera</i> --></div><div class="list no-hairlines-md"><button class="button button-cambiar-imagen"><i class="icon f7-icons ios-only">camera</i> <i class="icon material-icons md-only">photo_camera</i> Cambiar imagen</button> <input hidden type=file name=file accept="image/png, image/jpeg"></div></div></div>';
      return r;
    }(this);
  },

  style: `
    .img-establecimiento {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        border: 4px solid white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        object-fit: contain;
    }
    
    .icon-overlay {
        font-size: 30px;
        position: absolute;
        bottom: 4%;
        right: 4%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
    }
`,
  styleScoped: false
};