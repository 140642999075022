import { listItemEstablecimiento } from './js/cart-sessionstorage/cart-sessionstorage.js';
export default {
  methods: {
    getEstablecimiento(idEstablecimiento) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var url = window.global.url + "establecimientos/" + idEstablecimiento;
      app.preloader.show();
      return app.request.getJSON(url, function (data) {
        if (data.status == 'success') {
          let establecimiento = data.establecimiento;
          localStorage.setItem('_establecimiento', JSON.stringify(establecimiento));

          if (establecimiento.configuracion.desactivar_carrito) {
            $('.cart-fab').addClass("display-none");
          }
        } else if (data.code == 404) {
          app.views.main.router.navigate({
            path: '/'
          });
          app.dialog.alert(data.message);
        } else {
          app.dialog.alert(data.message);
        }

        app.preloader.hide();
      }, function (xhr, status) {
        console.log(xhr);
        console.log(status);
        app.preloader.hide();
        app.dialog.alert("Error establecimiento.");
      });
    },

    ocultarCarrito() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      let currentRouteName = app.view.main.router.currentRoute.name;

      if (currentRouteName !== "principal-menu" && currentRouteName !== "items" && currentRouteName !== "item-detalle" && currentRouteName !== "search-results") {
        $('.cart-fab').addClass("display-none");
      }
    }

  },
  on: {
    pageBeforeRemove() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      $('.cart-fab').off("click");
    },

    pageBeforeIn() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.ocultarCarrito();
    },

    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimientoParam = app.view.main.router.currentRoute.params.idEstablecimiento;
      var idMesaParam = app.view.main.router.currentRoute.params.idMesa;
      let itemList = listItemEstablecimiento(idEstablecimientoParam);

      if (itemList.length > 0) {
        let cantidad_total = 0;

        for (let i = 0; i < itemList.length; i++) {
          const element = itemList[i].quantity;
          cantidad_total += element;
        }

        $('.cart-icon').append($('<span class="badge-carrito badge color-red">' + cantidad_total + '</span>'));
      }

      if (itemList.length == 0) {
        $('.cart-icon').append($('<span class="badge-carrito badge color-red"></span>'));
        $('.badge-carrito').text('0');
      }

      $('.cart-fab').on('click', function () {
        let itemList = listItemEstablecimiento(idEstablecimientoParam);

        if (itemList.length > 0) {
          app.views.main.router.navigate('cart');
        } else {
          app.dialog.alert("El carrito está vacío.<br>Agregá algo al carrito para continuar.", "Webmenú");
        }
      });
    }

  },
  id: 'a6353d9865',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div id=app><!-- Left panel with cover effect--><!-- <div class="panel panel-left panel-cover theme-dark panel-init">\n      <div class="view">\n        <div class="page">\n          <div class="navbar">\n            <div class="navbar-bg"></div>\n            <div class="navbar-inner">\n              <div class="title">Left Panel</div>\n            </div>\n          </div>\n          <div class="page-content">\n            <div class="block">Left panel content goes here</div>\n          </div>\n        </div>\n      </div>\n    </div> --><!-- Right panel with reveal effect--><!-- <div class="panel panel-right panel-reveal theme-dark">\n      <div class="view">\n        <div class="page">\n          <div class="navbar">\n            <div class="navbar-bg"></div>\n            <div class="navbar-inner">\n              <div class="title">Right Panel</div>\n            </div>\n          </div>\n          <div class="page-content">\n            <div class="block">Right panel content goes here</div>\n          </div>\n        </div>\n      </div>\n    </div> --><!-- Your main view, should have "view-main" class --><div class="view view-main view-init safe-areas" data-url=/ ><!-- Floating Action Button --><div class="fab fab-right-bottom cart-fab display-none"><a href=#><i class="cart-icon icon fas fa-shopping-cart"></i></a></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};