export default {
  methods: {
    getEstablecimiento(idEstablecimiento) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimientoParam = app.view.main.router.currentRoute.params.idEstablecimiento;
      var idMesaParam = app.view.main.router.currentRoute.params.idMesa;
      var url = window.global.url + "establecimientos/" + idEstablecimiento;
      var urlImagen = window.global.url + "imagen/establecimiento/" + idEstablecimiento + "/";
      app.preloader.show();
      app.request.getJSON(url, function (data) {
        if (data.status == 'success') {
          let establecimiento = data.establecimiento;
          localStorage.setItem('_establecimiento', JSON.stringify(establecimiento));

          if (establecimiento.estado == 1 && establecimiento.configuracion.desactivar_menu == 0) {
            $('.nombre-establecimiento').text(establecimiento.nombre);

            if (establecimiento.imagen) {
              urlImagen = $('.page[data-name="seleccionar-tipo-envio"] .img-establecimiento').attr('src', urlImagen.concat(establecimiento.imagen));
            } else {
              urlImagen = $('.page[data-name="seleccionar-tipo-envio"] .img-establecimiento').attr('src', 'static/image-placeholder.png');
            }

            if (establecimiento.direccion && !establecimiento.configuracion.ocultar_direccion) {
              $('.page[data-name="seleccionar-tipo-envio"] .direccion-text').empty().append('<i class="icon material-icons md-only">place</i><i class="icon f7-icons ios-only">map_pin</i><a href="https://www.google.com/maps/search/?api=1&query=' + establecimiento.direccion + '" target="_blank" class="item-link item-content link external">' + establecimiento.direccion + '</a>');
            }

            if (establecimiento.horarios && !establecimiento.configuracion.ocultar_horarios) {
              $('.page[data-name="seleccionar-tipo-envio"] .horarios-text').empty().append('<i class="icon material-icons md-only">schedule</i><i class="icon f7-icons ios-only">clock</i> ' + establecimiento.horarios);
            }

            if (establecimiento.configuracion.pedido_en_el_lugar) {
              $('.button-en-el-lugar').removeClass('display-none');
            }

            if (establecimiento.configuracion.takeaway) {
              $('.button-takeaway').removeClass('display-none');
            }

            if (establecimiento.configuracion.delivery) {
              $('.button-delivery').removeClass('display-none');
            }

            if (establecimiento.configuracion.envio_personalizado) {
              $('.button-envio-personalizado').text(establecimiento.configuracion.nombre_envio_personalizado);
              $('.button-envio-personalizado').removeClass('display-none');
            }
          } else if (establecimiento.estado == 1 && establecimiento.configuracion.desactivar_menu == 1) {
            app.views.main.router.navigate({
              name: 'carta-no-disponible',
              params: {
                idEstablecimiento: idEstablecimientoParam,
                idMesa: idMesaParam
              }
            });
          } else if (establecimiento.estado != 1) {
            app.views.main.router.navigate({
              path: '/'
            });
          }
        } else if (data.code == 404) {
          app.views.main.router.navigate({
            path: '/'
          });
        }

        app.preloader.hide();
      }, function (xhr, status) {
        console.log(xhr);
        console.log(status);
        app.preloader.hide();
        app.dialog.alert("Error establecimiento.");
      });
    }

  },
  on: {
    pageBeforeIn() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimientoParam = app.view.main.router.currentRoute.params.idEstablecimiento;
      $('.page[data-name="seleccionar-tipo-envio"] .direccion-text').empty();
      $('.page[data-name="seleccionar-tipo-envio"] .horarios-text').empty();
      self.getEstablecimiento(idEstablecimientoParam);
    },

    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimientoParam = app.view.main.router.currentRoute.params.idEstablecimiento;
      var idMesaParam = app.view.main.router.currentRoute.params.idMesa; // $('body').addClass('theme-dark color-theme-pink');
      // $('body').addClass('color-theme-black');

      $('.page[data-name="seleccionar-tipo-envio"] .button-en-el-lugar').on('click', function () {
        sessionStorage.setItem('_tipoenvio', 'en-el-lugar');
        app.views.main.router.navigate({
          name: 'principal-menu',
          params: {
            idEstablecimiento: idEstablecimientoParam,
            idMesa: idMesaParam
          }
        });
      });
      $('.page[data-name="seleccionar-tipo-envio"] .button-takeaway').on('click', function () {
        sessionStorage.setItem('_tipoenvio', 'takeaway');
        app.views.main.router.navigate({
          name: 'principal-menu',
          params: {
            idEstablecimiento: idEstablecimientoParam,
            idMesa: idMesaParam
          }
        });
      });
      $('.page[data-name="seleccionar-tipo-envio"] .button-delivery').on('click', function () {
        sessionStorage.setItem('_tipoenvio', 'delivery');
        app.views.main.router.navigate({
          name: 'principal-menu',
          params: {
            idEstablecimiento: idEstablecimientoParam,
            idMesa: idMesaParam
          }
        });
      });
      $('.page[data-name="seleccionar-tipo-envio"] .button-envio-personalizado').on('click', function () {
        sessionStorage.setItem('_tipoenvio', 'personalizado');
        app.views.main.router.navigate({
          name: 'principal-menu',
          params: {
            idEstablecimiento: idEstablecimientoParam,
            idMesa: idMesaParam
          }
        });
      });
    }

  },
  id: 'd8d0a4faca',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=seleccionar-tipo-envio><div class=page-content><div class="row justify-content-center"><div class="col-100 medium-60 large-40"><div class="block text-align-center"><img class=img-establecimiento><div class="row text-align-center"><div class=col><p class=direccion-text></p><p class="horarios-text no-margin-bottom"></p></div></div></div><div class="block block-strong"><p class=row></p><h3 class="col text-align-center">¡Bienvenid@!</h3><h3 class="col text-align-center">Selecciona una opción</h3><p></p><p class=row></p><div class="col-100 medium-60 large-30"><a href=# class="button button-large button-round button-outline button-en-el-lugar display-none">Estoy en el lugar</a></div><p></p><p class=row></p><div class="col-100 medium-60 large-30"><a href=# class="button button-large button-round button-outline button-takeaway display-none">Take Away</a></div><p></p><p class=row></p><div class="col-100 medium-60 large-30"><a href=# class="button button-large button-round button-outline button-delivery display-none">Delivery</a></div><p></p><p class=row></p><div class="col-100 medium-60 large-30"><a href=# class="button button-large button-round button-outline button-envio-personalizado display-none"></a></div><p></p></div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
    .img-establecimiento {
        max-width: 100%;
        max-height: 140px;
    }
`,
  styleScoped: false
};