export default {
  methods: {
    getEstablecimientos() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var usuario = JSON.parse(localStorage.getItem('usuario'));
      var url = window.global.url + "usuarios";
      app.request({
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'GET',
        statusCode: {
          404: function (xhr) {
            console.log("URL not found");
          },
          400: function (xhr) {
            console.log("Bad request. Some of the inputs provided to the request aren't valid.");
          },
          401: function (xhr) {
            console.log("Not authenticated. The user session isn't valid.");
          },
          403: function (xhr) {
            // console.log("The user isn't authorized to perform the specified request.");
            app.views.main.router.navigate({
              path: '/'
            });
          },
          500: function (xhr) {
            console.log("Internal server error. Additional details will be contained on the server logs.");
          },
          201: function (xhr) {
            console.log("The requested resource has been created.");
          }
        },
        success: function (response, status, xhr) {
          if (response.code == 200) {
            let usuarios = response.usuarios;
            var virtualList = app.virtualList.create({
              // List Element
              el: '.virtual-list-usuarios',
              // Pass array with items
              items: usuarios,
              // Custom search function for searchbar
              searchAll: function (query, items) {
                var found = [];
                let queryNormalized = query.normalize('NFD').replace(/[\u0300-\u036f]/g, "").normalize().toLowerCase().trim();

                for (var i = 0; i < items.length; i++) {
                  if (items[i].nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(queryNormalized) >= 0 || items[i].apellido.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(queryNormalized) >= 0 || items[i].email.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(queryNormalized) >= 0 || query.trim() === '') found.push(i);

                  if (items[i].establecimientos[0].nombre) {
                    if (items[i].establecimientos[0].nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(queryNormalized) >= 0 || query.trim() === '') found.push(i);
                  }
                }

                return found; //return array with mathced indexes
              },
              // List item Template7 template
              itemTemplate: '<li>' + '<a href="mod/{{id_usuario}}" class="item-link item-content">' + '<div class="item-inner">' + '<div class="item-title-row">' + '<div class="item-title">{{nombre}} {{apellido}}</div>' + '<div class="item-after">{{establecimientos[0].nombre}}</div>' + '</div>' + '{{#if estado}}' + '<div class="item-subtitle">Habilitado</div>' + '{{else}}' + '<div class="item-subtitle">Deshabilitado</div>' + '{{/if}}' + '</div>' + '</a>' + '</li>',
              // Item height
              height: app.theme === 'ios' ? 63 : app.theme === 'md' ? 73 : 46
            });
          }
        },
        error: function (xhr, status) {
          console.log(xhr);
          console.log(JSON.stringify(xhr));
          console.log(status);
          app.dialog.alert("Error al obtener usuarios.");
        }
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getEstablecimientos();
    }

  },
  id: '9f69f29e94',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=superadmin-usuarios-list><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Usuarios</div><div class=right></div><div class=subnavbar><form data-search-container=.virtual-list-usuarios data-search-item=li data-search-in=.item-title class="searchbar searchbar-init"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Buscar usuarios"> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancelar</span></div></form></div></div></div><div class=searchbar-backdrop></div><!-- Scrollable page content--><div class=page-content><div class="list simple-list searchbar-not-found"><ul><li>No se encontraron resultados.</li></ul></div><div class="list virtual-list virtual-list-usuarios media-list searchbar-found"></div></div><!-- Floating Action Button --><div class="fab fab-right-bottom"><a href=form><i class="icon f7-icons">plus</i></a></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};