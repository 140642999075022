export default {
  methods: {
    getEstablecimiento(establecimiento) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var url = window.global.url + "establecimientos/" + establecimiento.id_establecimiento;
      var urlImagen = window.global.url + "imagen/establecimiento/" + establecimiento.id_establecimiento + "/";
      $('.nombre-establecimiento').text(establecimiento.nombre);

      if (establecimiento.imagen) {
        urlImagen = $('.page[data-name="carta-no-disponible"] .img-establecimiento').attr('src', urlImagen.concat(establecimiento.imagen));
      } else {
        urlImagen = $('.page[data-name="carta-no-disponible"] .img-establecimiento').attr('src', 'static/image-placeholder.png');
      } // Mensaje de menú desactivado


      if (establecimiento.configuracion.mensaje_menu_no_disponible) {
        $('.mensaje-text').text(establecimiento.configuracion.mensaje_menu_no_disponible);
      }

      self.aboutPopUp(establecimiento);
    },

    aboutPopUp(establecimiento) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var urlImagen = "static/wm-logo.png";

      if (establecimiento.imagen) {
        urlImagen = window.global.url + "imagen/establecimiento/" + establecimiento.id_establecimiento + "/" + establecimiento.imagen;
      } // Create dynamic Popup


      var dynamicPopup = app.popup.create({
        content: '<div class="popup popup-info">' + '<div class="page">' + '<div class="navbar">' + '<div class="navbar-bg"></div>' + '<div class="navbar-inner">' + '<div class="title">Información</div>' + '<div class="right">' + '<a href="#" class="link popup-close">Cerrar</a>' + '</div>' + '</div>' + '</div>' + '<div class="page-content">' + '<div class="block">' + '<p></p>' + '<div class="text-align-center">' + '<img src="' + urlImagen + '" class="img-establecimiento" alt="">' + '</div>' + '</div>' + '<div class="block text-align-center"><h2>' + establecimiento.nombre + '</h2></div>' + '<div class="list no-hairlines">' + '<ul>' + '<li class="li-info-direccion display-none">' + '<div class="item-content">' + '<div class="item-media">' + '<i class="icon material-icons md-only">place</i>' + '<i class="icon f7-icons ios-only">map_pin</i>' + '</div>' + '<div class="item-inner">' + '<div class="item-title">' + establecimiento.direccion + '</div>' + // '<div class="item-after">CEO</div>' +
        '</div>' + '</div>' + '</li>' + '<li class="li-info-telefono display-none">' + '<div class="item-content">' + '<div class="item-media">' + '<i class="icon material-icons md-only">phone</i>' + '<i class="icon f7-icons ios-only">phone</i>' + '</div>' + '<div class="item-inner">' + '<div class="item-title">' + establecimiento.telefono + '</div>' + // '<div class="item-after">CEO</div>' +
        '</div>' + '</div>' + '</li>' + '<li class="li-info-horarios display-none">' + '<div class="item-content">' + '<div class="item-media">' + '<i class="icon material-icons md-only">schedule</i>' + '<i class="icon f7-icons ios-only">clock</i>' + '</div>' + '<div class="item-inner">' + '<div class="item-title" style="--f7-list-item-title-white-space: none;">' + establecimiento.horarios + '</div>' + // '<div class="item-after">CEO</div>' +
        '</div>' + '</div>' + '</li>' + '<li class="li-info-email display-none">' + '<div class="item-content">' + '<div class="item-media">' + '<i class="icon material-icons md-only">mail</i>' + '<i class="icon f7-icons ios-only">envelope</i>' + '</div>' + '<div class="item-inner">' + '<div class="item-title">' + establecimiento.email + '</div>' + // '<div class="item-after">CEO</div>' +
        '</div>' + '</div>' + '</li>' + '<li class="li-info-facebook display-none">' + '<a href="' + establecimiento.facebook + '" target="_blank" class="item-link item-content link external">' + '<div class="item-media"><i class="icon fab fa-facebook md-only"></i><i class="icon f7-icons ios-only">logo_facebook</i></div>' + '<div class="item-inner">' + '<div class="item-title">Facebook</div>' + '</div>' + '</a>' + '</li>' + '<li class="li-info-instagram display-none">' + '<a href="' + establecimiento.instagram + '" target="_blank" class="item-link item-content link external">' + '<div class="item-media"><i class="icon fab fa-instagram md-only"></i><i class="icon f7-icons ios-only">logo_instagram</i></div>' + '<div class="item-inner">' + '<div class="item-title">Instagram</div>' + '</div>' + '</a>' + '</li>' + '</ul>' + '</div>' + '</div>' + '</div>' + '</div>',
        // Events
        on: {
          open: function (popup) {
            if (establecimiento.direccion || establecimiento.direccion == "") {
              $('.li-info-direccion').removeClass('display-none');
            }

            if (establecimiento.telefono || establecimiento.telefono == "") {
              $('.li-info-telefono').removeClass('display-none');
            }

            if (establecimiento.horarios || establecimiento.horarios == "") {
              $('.li-info-horarios').removeClass('display-none');
            }

            if (establecimiento.email || establecimiento.email == "") {
              $('.li-info-email').removeClass('display-none');
            }

            if (establecimiento.facebook || establecimiento.facebook == "") {
              $('.li-info-facebook').removeClass('display-none');
            }

            if (establecimiento.instagram || establecimiento.instagram == "") {
              $('.li-info-instagram').removeClass('display-none');
            }
          },
          opened: function (popup) {// console.log('Popup opened');
          }
        }
      }); // Open dynamic popup

      $('.dynamic-popup').on('click', function () {
        dynamicPopup.open();
      });
    }

  },
  on: {
    pageBeforeIn() {},

    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var establecimiento = JSON.parse(localStorage.getItem('_establecimiento'));
      self.getEstablecimiento(establecimiento);
    }

  },
  id: 'fd80f7d4b1',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=carta-no-disponible><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left></div><div class="title nombre-establecimiento"></div><div class=right><a href=# class="link dynamic-popup"><i class="icon material-icons md-only">info</i> <i class="icon f7-icons ios-only">info_circle</i></a></div></div></div><div class=page-content><div class="block text-align-center"><img class=img-establecimiento></div><div class="block text-align-center"><h2 class=mensaje-text></h2></div></div></div>';
      return r;
    }(this);
  },

  style: `
    .img-establecimiento {
        max-width: 100%;
        max-height: 140px;
    }
`,
  styleScoped: false
};