import { add, listItemEstablecimiento } from '../js/cart-sessionstorage/cart-sessionstorage.js';
export default {
  methods: {
    getItem() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idItem = app.view.main.router.currentRoute.params.idItem;
      var tipoEnvio = sessionStorage.getItem('_tipoenvio');
      var url = window.global.url + "items/" + idItem;
      app.preloader.show();
      app.request.json(url, function (response) {
        if (response.code == 200) {
          var item = response.item;
          var imageUrl = window.global.url + 'imagen/item/' + idItem + '/' + item.imagen;
          $('.page[data-name="item-detalle"] .item-title').html(item.nombre);

          if (item.imagen) {
            $('.page[data-name="item-detalle"] .card').addClass('demo-card-header-pic');
            $('.page[data-name="item-detalle"] .card-item-detalle .card-header').css('background-image', 'url(' + imageUrl + ')');
          } else {
            $('.page[data-name="item-detalle"] .card-item-detalle .item-title').removeClass('text-shadow');
            $('.page[data-name="item-detalle"] .card-item-detalle .item-title').addClass('text-color-black');
          }

          if (item.descripcion) {
            $('.page[data-name="item-detalle"] .card-item-detalle .item-descripcion').html(item.descripcion);
          }

          if (item.mostrar_precio) {
            if (tipoEnvio == 'en-el-lugar' && item.precio) {
              $('.page[data-name="item-detalle"] .card-item-detalle .item-precio').text("$ " + item.precio);
            } else if (tipoEnvio == 'takeaway' && item.precio_takeaway) {
              $('.page[data-name="item-detalle"] .card-item-detalle .item-precio').text("$ " + item.precio_takeaway);
            } else if (tipoEnvio == 'delivery' && item.precio_delivery) {
              $('.page[data-name="item-detalle"] .card-item-detalle .item-precio').text("$ " + item.precio_delivery);
            } else if (tipoEnvio == 'personalizado' && item.precio_envio_personalizado) {
              $('.page[data-name="item-detalle"] .card-item-detalle .item-precio').text("$ " + item.precio_envio_personalizado);
            }
          }

          $('.agregar-item-button').on('click', function () {
            let cantidad = $('[name="item-cantidad"]').val();

            if (tipoEnvio == 'en-el-lugar') {
              self.agregarItemAlCarrito(item.id_item, item.nombre, item.precio, item.precio_takeaway, item.precio_delivery, item.precio_envio_personalizado, item.imagen, item.categoria.nombre, parseInt(cantidad), item.mostrar_precio);
            } else if (tipoEnvio == 'takeaway') {
              self.agregarItemAlCarrito(item.id_item, item.nombre, item.precio, item.precio_takeaway, item.precio_delivery, item.precio_envio_personalizado, item.imagen, item.categoria.nombre, parseInt(cantidad), item.mostrar_precio);
            } else if (tipoEnvio == 'delivery') {
              self.agregarItemAlCarrito(item.id_item, item.nombre, item.precio, item.precio_takeaway, item.precio_delivery, item.precio_envio_personalizado, item.imagen, item.categoria.nombre, parseInt(cantidad), item.mostrar_precio);
            } else if (tipoEnvio == 'personalizado') {
              self.agregarItemAlCarrito(item.id_item, item.nombre, item.precio, item.precio_takeaway, item.precio_delivery, item.precio_envio_personalizado, item.imagen, item.categoria.nombre, parseInt(cantidad), item.mostrar_precio);
            }
          });
        }

        app.preloader.hide();
      });
    },

    agregarItemAlCarrito(idItem, nombreItem, precio, precioTakeaway, precioDelivery, precioEnvioPersonalizado, imagenItem, categoriaItem, cantidad, mostrarPrecio) {
      let idEstablecimiento = JSON.parse(localStorage.getItem('_establecimiento')).id_establecimiento;
      add({
        id: idItem,
        name: nombreItem,
        price: precio,
        price_takeaway: precioTakeaway,
        price_delivery: precioDelivery,
        price_personalizado: precioEnvioPersonalizado,
        categoria: categoriaItem,
        imagen: imagenItem,
        id_establecimiento: idEstablecimiento,
        mostrar_precio: mostrarPrecio
      }, cantidad);
      this.actualizarCarrito();
      this.openToastItem();
      this.logCart("add", idItem);
    },

    openToastItem() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var toastBottom = app.toast.create({
        text: 'Item agregado al carrito.',
        closeTimeout: 3000,
        destroyOnClose: true,
        closeButton: true,
        closeButtonText: 'Cerrar'
      });
      toastBottom.open();
    },

    actualizarCarrito() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimientoParam = app.view.main.router.currentRoute.params.idEstablecimiento;
      let itemList = listItemEstablecimiento(idEstablecimientoParam);

      if (itemList.length > 0) {
        let cantidad_total = 0;

        for (let i = 0; i < itemList.length; i++) {
          const element = itemList[i].quantity;
          cantidad_total += element;
        }

        $('.cart-icon').append($('<span class="badge-carrito badge color-red">' + cantidad_total + '</span>'));
      }

      if (itemList.length == 0) {
        $('.badge-carrito').remove();
      }
    },

    logCart(option, id_item) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var idEstablecimientoParam = app.view.main.router.currentRoute.params.idEstablecimiento;
      let url = window.global.url + "carrito/log/" + option;
      let cart = {
        id_establecimiento: idEstablecimientoParam
      };

      if (id_item) {
        cart = {
          id_establecimiento: idEstablecimientoParam,
          id_item: id_item
        };
      }

      app.preloader.show();
      app.request.postJSON(url, function (data) {});
      app.preloader.hide();
    }

  },
  on: {
    pageBeforeIn() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      let establecimiento = JSON.parse(localStorage.getItem("_establecimiento"));
      let tipoEnvio = sessionStorage.getItem('_tipoenvio');

      if (tipoEnvio == "en-el-lugar" && establecimiento.configuracion.desactivar_carrito_en_el_lugar) {
        $('.row-agregar-carrito').addClass("display-none");
      }

      if (tipoEnvio == "takeaway" && establecimiento.configuracion.desactivar_carrito_takeaway) {
        $('.row-agregar-carrito').addClass("display-none");
      }

      if (tipoEnvio == "delivery" && establecimiento.configuracion.desactivar_carrito_delivery) {
        $('.row-agregar-carrito').addClass("display-none");
      }

      if (tipoEnvio == "personalizado" && establecimiento.configuracion.desactivar_carrito_envio_personalizado) {
        $('.row-agregar-carrito').addClass("display-none");
      }
    },

    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getItem();
    }

  },
  id: '3652dcf9d4',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=item-detalle><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class="title item-title"></div><div class=right></div></div></div><div class=page-content><div class=block><div class="row justify-content-center"><div class="col-100 medium-100 large-50"><div class="card card-item-detalle"><div class="card-header align-items-flex-end"><h2 class="item-title text-shadow"></h2></div><div class="card-content card-content-padding"><p class=item-descripcion></p><b><span class=item-precio></span></b><div class="padding-top row-agregar-carrito"><div class="row text-align-center"><div class=col><div class="stepper stepper-raised stepper-init"><div class=stepper-button-minus></div><div class=stepper-input-wrap><input name=item-cantidad type=number value=1 min=1 max=10 step=1 readonly=readonly></div><div class=stepper-button-plus></div></div></div></div><div class="row padding-top"><div class=col><button class="button button-raised button-fill agregar-item-button">Agregar al carrito <i class="icon fas fa-cart-plus md-only"></i> <i class="icon f7-icons ios-only">cart_badge_plus</i></button></div></div></div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};