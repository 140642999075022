export default {
  id: '219863363c',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>404</div><div class=right></div></div></div><div class=page-content><div class="block block-strong"><div class="row justify-content-center"><div class="col-100 medium-60 large-30"><p>Ups...</p><p>La página no ha sido encontrada.</p><img src=static/404.svg width=100% alt=""></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};