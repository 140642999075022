export default {
  methods: {
    getEstablecimientosUsuario() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var usuario = JSON.parse(localStorage.getItem('usuario'));
      var idUsuario = usuario.id_usuario;
      var url = window.global.url + "usuario/establecimientos/habilitados/" + idUsuario;
      app.request.json(url, function (response) {
        if (response.code == 200) {
          let establecimientos = response.usuario_establecimientos;

          if (!Array.isArray(establecimientos)) {
            establecimientos = Object.values(establecimientos);
          }

          var virtualList = app.virtualList.create({
            // List Element
            el: '.virtual-list-establecimientos',
            // Pass array with items
            items: establecimientos,
            // Custom search function for searchbar
            searchAll: function (query, items) {
              var found = [];
              let queryNormalized = query.normalize('NFD').replace(/[\u0300-\u036f]/g, "").normalize().toLowerCase().trim();

              for (var i = 0; i < items.length; i++) {
                if (items[i].nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(queryNormalized) >= 0 || query.trim() === '') found.push(i);
              }

              return found; //return array with mathced indexes
            },
            // List item Template7 template
            itemTemplate: '<li>' + '<a href="/admin/establecimiento/{{id_establecimiento}}" class="item-link item-content">' + '<div class="item-inner">' + '<div class="item-title-row">' + '<div class="item-title">{{nombre}}</div>' + '</div>' + '<div class="item-subtitle">{{direccion}}</div>' + '</div>' + '</a>' + '</li>',
            // Item height
            height: app.theme === 'ios' ? 63 : app.theme === 'md' ? 73 : 46
          });
        }
      });
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getEstablecimientosUsuario();
      $('.page[data-name="admin-establecimientos-list"] .back-button').on('click', function () {
        localStorage.clear();
        sessionStorage.clear();
        app.views.main.router.navigate({
          path: '/'
        });
      });
    }

  },
  id: '22b2d43295',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=admin-establecimientos-list><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back-button"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Establecimientos</div><div class=right></div><div class=subnavbar><form data-search-container=.virtual-list-establecimientos data-search-item=li data-search-in=.item-title class="searchbar searchbar-init"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=Buscar> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancelar</span></div></form></div></div></div><div class=searchbar-backdrop></div><!-- Scrollable page content--><div class=page-content><div class="list simple-list searchbar-not-found"><ul><li>No se encontraron resultados</li></ul></div><div class="list virtual-list virtual-list-establecimientos media-list searchbar-found"></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};