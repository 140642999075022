export default {
  methods: {
    enviarComentario(calificacion) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var url = window.global.url + "calificaciones";
      var usuario = JSON.parse(localStorage.getItem('usuario'));
      var idEstablecimiento = app.view.main.router.currentRoute.params.idEstablecimiento;
      var nombre = $('input[type=text][name=nombre]').val().trim();
      var comentario = $('textarea[name=comentario]').val().trim();
      let calificacionObject = {
        "id_establecimiento": idEstablecimiento,
        "nombre": nombre,
        "comentario": comentario,
        "calificacion": calificacion
      };
      app.request({
        url: url,
        dataType: 'json',
        contentType: "application/json",
        method: 'POST',
        data: calificacionObject,
        statusCode: {
          404: function (xhr) {
            console.log("URL not found");
          },
          400: function (xhr) {
            console.log("Bad request. Some of the inputs provided to the request aren't valid.");
          },
          401: function (xhr) {
            console.log("Not authenticated. The user session isn't valid.");
          },
          403: function (xhr) {
            console.log("The user isn't authorized to perform the specified request.");
            app.views.main.router.navigate({
              path: '/'
            });
          },
          500: function (xhr) {
            console.log("Internal server error. Additional details will be contained on the server logs.");
          },
          201: function (xhr) {
            console.log("The requested resource has been created.");
          }
        },
        success: function (data, status, xhr) {
          if (data.status == 'success') {
            app.dialog.alert("Calificación enviada con éxito. ¡Gracias!");
            self.reiniciarCampos();
            self.validarCampos();
            app.views.main.router.back();
          } else {
            app.dialog.alert("Error al enviar la calificación. Vuelva a intentarlo.");
          }
        },
        error: function (xhr, status) {
          console.log(xhr);
          console.log(JSON.stringify(xhr));
          console.log(status);
          app.dialog.alert("Error al enviar la calificación. Vuelva a intentarlo.");
        }
      });
    },

    reiniciarCampos() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      $('.emoji-frown').removeClass('text-color-red').addClass('text-color-gray');
      $('.emoji-laugh').removeClass('text-color-green').addClass('text-color-gray');
      $('.emoji-mensaje-error').addClass('display-none');
      $('input[type=text][name=nombre]').val("");
      $('textarea[name=comentario]').val("");
      $('.item-nombre-apellido-input').removeClass('item-input-with-error-message');
      $('.item-nombre-apellido-input').removeClass('item-input-invalid');
    },

    validarCampos(calificacion) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var nombre = $('input[type=text][name=nombre]').val().trim();

      if (nombre.length < 3) {
        $('.enviar-comentario-button').addClass('disabled');
        return false;
      }

      if (nombre.length < 3 || calificacion == 99) {
        $('.emoji-mensaje-error').removeClass('display-none');
        $('.enviar-comentario-button').addClass('disabled');
        return false;
      }

      $('.emoji-mensaje-error').addClass('display-none');
      $('.enviar-comentario-button').removeClass('disabled');
      return true;
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      let establecimiento = JSON.parse(localStorage.getItem('_establecimiento'));
      let calificacion = 99;

      if (establecimiento.imagen) {
        let urlImagen = window.global.url + "imagen/establecimiento/" + establecimiento.id_establecimiento + "/" + establecimiento.imagen;
        $('.img-establecimiento').attr('src', urlImagen);
      }

      $('.emoji-frown').on('click', function () {
        calificacion = 0;
        self.validarCampos(calificacion);

        if ($('.emoji-frown').hasClass('text-color-gray')) {
          $('.emoji-frown').removeClass('text-color-gray').addClass('text-color-red');
          $('.emoji-laugh').removeClass('text-color-green').addClass('text-color-gray');
        } else {
          $('.emoji-laugh').removeClass('text-color-green').addClass('text-color-gray');
        }
      });
      $('.emoji-laugh').on('click', function () {
        calificacion = 1;
        self.validarCampos(calificacion);

        if ($('.emoji-laugh').hasClass('text-color-gray')) {
          $('.emoji-laugh').removeClass('text-color-gray').addClass('text-color-green');
          $('.emoji-frown').removeClass('text-color-red').addClass('text-color-gray');
        } else {
          $('.emoji-frown').removeClass('text-color-red').addClass('text-color-gray');
        }
      });
      $('input[name=nombre]').on('input', function () {
        self.validarCampos(calificacion);
      });
      $('.enviar-comentario-button').on('click', function () {
        self.enviarComentario(calificacion);
      });
    }

  },
  id: '5093671b39',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=popup-calificacion><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class=title>Calificanos</div><div class=right></div></div></div><div class=page-content><div class=block><div class=text-align-center><img src="" class=img-establecimiento alt=""></div><div class=text-align-center><h2>¡Tu opinión nos importa!</h2><p class=text-size-custom>Ayudanos a mejorar brindando tus opiniones o sugerencias sobre tu experiencia en nuestro comercio.</p><div>Tu comentario permanecerá privado.</div></div></div><div class=block><div class="row text-align-center"><div class=col><i class="icon emoji-icon emoji-frown text-color-gray ripple margin-right far fa-frown"></i> <i class="icon emoji-icon emoji-laugh text-color-gray ripple margin-left far fa-laugh-beam"></i><div class="emoji-mensaje-error margin-top text-color-red display-none">Por favor, seleccione un ícono.</div></div></div><div class="list no-hairlines"><ul><li><div class="item-content item-input item-nombre-apellido-input"><div class=item-inner><div class="item-title item-label">Nombre y apellido</div><div class=item-input-wrap><input type=text name=nombre placeholder="Ingresá tu nombre y apellido" maxlength=60 pattern=.{3,} required validate data-error-message="El nombre debe tener al menos 3 caracteres."></div></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">Comentario (opcional)</div><div class=item-input-wrap><textarea class=resizable name=comentario placeholder="Escribí tu comentario" maxlength=250></textarea></div></div></li><li><a href=# class="list-button text-align-center text-color-blue enviar-comentario-button disabled">Enviar</a></li></ul></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
    .img-establecimiento {
        max-width: 100%;
        max-height: 140px;
    }
    
    .emoji-icon {
        font-size: 60px;
    }
    
    .text-size-custom {
        font-size: 16px;
    }
`,
  styleScoped: false
};