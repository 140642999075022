export default {
  methods: {
    getImagenesQr() {
      if (sessionStorage.getItem('_establecimiento')) {
        let establecimiento = JSON.parse(sessionStorage.getItem('_establecimiento'));
        let idEstablecimiento = establecimiento.id_establecimiento;
        let urlPersonalizada = establecimiento.url_personalizada;
        let nombre = urlPersonalizada.substring(urlPersonalizada.lastIndexOf('/') + 1);

        if (idEstablecimiento < 10) {
          idEstablecimiento = '0' + idEstablecimiento;
        }

        let linkImagenQr = ['http://webmenu.com.ar/' + nombre + '/qr100' + idEstablecimiento + '.png'];
        return linkImagenQr;
      }

      return false;
    }

  },
  on: {
    pageInit() {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      self.getImagenesQr();
      var qrPhotoBrowser = app.photoBrowser.create({
        photos: self.getImagenesQr(),
        popupCloseLinkText: 'Cerrar',
        navbarOfText: 'de'
      });
      $('.page[data-name="mis-datos"] .button-qr').on('click', function () {
        qrPhotoBrowser.open();
      });
    }

  },
  id: 'f3ca0540a5',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=mis-datos><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Atrás</span></a></div><div class="title sliding">Mis datos</div><div class=right></div></div></div><!-- Scrollable page content--><div class=page-content><div class=block><p>Seleccioná una opción para editar tus datos.</p></div><div class=list><ul><li><a href=establecimiento-mod class="item-link item-content"><div class=item-media><i class="icon fas fa-store-alt"></i></div><div class=item-inner><div class=item-title>Datos del establecimiento</div></div></a></li><li><a href=imagen-mod class="item-link item-content"><div class=item-media><i class="icon fas fa-image"></i></div><div class=item-inner><div class=item-title>Imagen del establecimiento</div></div></a></li><li><a href=horarios-mod class="item-link item-content"><div class=item-media><i class="icon fas fa-clock"></i></div><div class=item-inner><div class=item-title>Horarios</div></div></a></li><li><a href=# class="item-link item-content button-qr"><div class=item-media><i class="icon fas fa-qrcode"></i></div><div class=item-inner><div class=item-title>Ver código QR</div></div></a></li><li><a href=redes-sociales-mod class="item-link item-content"><div class=item-media><i class="icon fas fa-share-alt"></i></div><div class=item-inner><div class=item-title>Redes sociales</div></div></a></li><li><a href=mensaje-mod class="item-link item-content"><div class=item-media><i class="icon fas fa-bullhorn"></i></div><div class=item-inner><div class=item-title>Mensaje</div></div></a></li><li><a href=change-password class="item-link item-content"><div class=item-media><i class="icon fas fa-lock"></i></div><div class=item-inner><div class=item-title>Cambiar contraseña</div></div></a></li></ul></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};